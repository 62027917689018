import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { StyleProvider as AntSSRStyleProvider } from '@ant-design/cssinjs';
import { ApolloProvider } from '@apollo/client';
import { GoogleTagManager } from '@next/third-parties/google';
import { App, ConfigProvider, notification } from 'antd';
import { ThemeProvider as StyledComponentProvider } from 'styled-components';

import { StoresInitializationProvider } from '@/_app/providers';
import { AuthProvider } from '@/_app/providers/auth-provider';
import { FormDrawerProvider } from '@/_app/providers/form-drawer-provider';
import { ZendeskProvider } from '@/features/zendesk/provider';
import { Meta } from '@/shared/components';
import { DebugPanel } from '@/shared/components/debug-panel';
import { antTheme } from '@/shared/constants/ant-theme';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useApolloClient } from '@/shared/services/apollo/apollo-client.service';
import { log } from '@/shared/utils/log';

// import NotificationsComponent from '@/widgets/notifications.component';
import '@/_app/global.css';
import '@/_app/colors.css';

import nextI18NextConfig from '../../next-i18next.config';

const handleError = (event: ErrorEvent): void => {
  log.error('Unhandled error', event.error);
};

const handlePromiseRejection = (event: PromiseRejectionEvent): void => {
  log.error('Unhandled promise rejection', event.reason);
};

const theme = {
  main: 'mediumseagreen',
};

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  getTitle?: () => string;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout): ReactNode {
  const apolloClient = useApolloClient();
  const getLayout = useCallback(Component.getLayout || ((page: ReactElement) => page), [Component]);
  const [isDebugOpen, setIsDebugOpen] = useState<boolean>(false);
  const router = useRouter();
  const [api, contextHolder] = notification.useNotification();

  const handleUncaughtException = (e: CustomEvent<{ collection: CollectionName }>) => {
    console.error({ e });
    api.error({
      message: 'MyHub works in read-only mode. Your data wasn’t saved',
      placement: 'top',
    });
  };

  const pageTitle = useMemo(() => {
    const description = 'Owner.One App - Assets Data Repository and Wealth Transfer';

    return Component.getTitle ? `${Component.getTitle()} | ${description}` : description;
  }, [Component]);

  useEffect(() => {
    // window._ = () => setIsDebugOpen(true);
    window._debug = {
      panelOpen: () => setIsDebugOpen(true),
      panelClose: () => setIsDebugOpen(false),
      navigate: async (url: string) => await router.push(url),
    };
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handlePromiseRejection);

    window.addEventListener('ExportedModeWriteErrorEvent', handleUncaughtException);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handlePromiseRejection);
      window.removeEventListener('ExportedModeWriteErrorEvent', handleUncaughtException);
    };
  }, []);

  if (!apolloClient) {
    return <div>ApolloClient is not initialized</div>;
  }

  const gaId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
  return (
    <>
      <Meta
        title={pageTitle}
        description={
          'Algorithmic transfer for your capital and assets data to your family. No third-parties. For owners of $3m-99m'
        }
      />
      {gaId && <GoogleTagManager gtmId={gaId} />}
      <StyledComponentProvider theme={theme}>
        <AntSSRStyleProvider hashPriority={'high'} ssrInline={true}>
          <ConfigProvider theme={antTheme}>
            <ApolloProvider client={apolloClient}>
              <StoresInitializationProvider>
                <AuthProvider>
                  <ZendeskProvider>
                    <FormDrawerProvider>
                      <App>
                        <main>
                          {/* <NotificationsComponent /> */}
                          {contextHolder}
                          {getLayout(<Component {...pageProps} />)}
                          {isDebugOpen && <DebugPanel />}
                        </main>
                      </App>
                    </FormDrawerProvider>
                  </ZendeskProvider>
                </AuthProvider>
              </StoresInitializationProvider>
            </ApolloProvider>
          </ConfigProvider>
        </AntSSRStyleProvider>
      </StyledComponentProvider>
    </>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
