import { UserOutlined } from '@ant-design/icons';

import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { BeneficiaryDto, BeneficiaryGroup } from '@/shared/lib/sj-orm/models/beneficiary.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export type NextOwnerDtoType = Omit<
  BeneficiaryDto,
  'id' | 'centralDbProfileId' | 'linkedCountryCode'
>;

export type NextOwnerDtoHelpType = {
  nickName: QuestionType.TEXT;
  firstName: QuestionType.TEXT;
  secondName: QuestionType.TEXT;
  lastName: QuestionType.TEXT;
  group: QuestionType.SELECT_FROM_LIST;
  email: QuestionType.EMAIL;
};

type UnilateralFormType = TDtoWrapper<NextOwnerDtoType, NextOwnerDtoHelpType>;

export const NextOwnerCreateForm: UnilateralFormType = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'Nickname *',
    required: true,
    value: '',
  },
  firstName: {
    type: QuestionType.TEXT,
    label: 'First name *',
    required: true,
    value: '',
  },
  secondName: {
    type: QuestionType.TEXT,
    label: 'Second name *',
    value: '',
  },
  lastName: {
    type: QuestionType.TEXT,
    label: 'Last name *',
    required: true,
    value: '',
  },
  group: {
    label: 'Group Affiliation *',
    placeholder: 'Select Group affiliation',
    required: true,
    value: BeneficiaryGroup.FAMILY,
    type: QuestionType.SELECT_FROM_LIST,
    items: [
      {
        label: 'Family',
        key: BeneficiaryGroup.FAMILY,
        icon: <UserOutlined />,
      },
      {
        label: 'Family Clan',
        key: BeneficiaryGroup.FAMILY_CLAN,
        icon: <UserOutlined />,
      },
      {
        label: 'Circle',
        key: BeneficiaryGroup.CIRCLE,
        icon: <UserOutlined />,
      },
    ],
  },
  email: {
    type: QuestionType.EMAIL,
    placeholder: 'Enter Email',
    label: 'Email *',
    required: true,
    value: '',
    collection: CollectionName.BENEFICIARIES,
  },
};
