import type { FC, MouseEventHandler, ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button as AntdButton, ButtonProps, Spin } from 'antd';
import { ButtonHTMLType } from 'antd/es/button';
import styled from 'styled-components';

interface IButton {
  text: string | ReactNode;
  type?: 'primary' | 'text' | 'link' | 'default' | 'dashed' | undefined;
  shape?: 'default' | 'circle' | 'round';
  icon?: ReactNode;
  textAlign?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  htmlType?: ButtonHTMLType;
  isBlock?: boolean;
}

export const Button: FC<IButton & ButtonProps> = ({
  size = 'large',
  text,
  type = 'primary',
  shape = 'default',
  icon,
  disabled,
  loading,
  onClick,
  htmlType,
  isBlock = true,
  ...props
}): JSX.Element => {
  return (
    <CustomButton
      block={isBlock}
      icon={icon}
      type={type}
      shape={shape}
      disabled={loading || disabled}
      size={size}
      onClick={onClick}
      htmlType={htmlType}
      {...props}
    >
      {loading && <Spin indicator={<LoadingOutlined spin />} />} {text}
    </CustomButton>
  );
};

const CustomButton = styled(AntdButton)``;
