import { TMigration } from '@/shared/lib/migrator/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';

const up = (store: Map<CollectionName, SecureJsonBase>) => {
  const assistantMnemonicCollection = store.get(CollectionName.ASSISTANT_MNEMONIC);

  if (assistantMnemonicCollection) {
    for (const [key, item] of assistantMnemonicCollection?.items || []) {
      const name = `${item.decryptedData.assistantName}`;
      assistantMnemonicCollection.set({
        key,
        data: { ...item.decryptedData, name },
      });
      delete (item.decryptedData as { assistantName?: string }).assistantName;
    }
  }
};

export const migration004: TMigration = {
  version: '0.0.4',
  up,
};
