import { createContext, PropsWithChildren, useContext } from 'react';

import { useGetZendeskTokenLazyQuery } from '@/shared/generated/graphql';
import { loadScript } from '@/shared/utils/dom/scripts';

import { useZendeskStore } from './stores/zendesk.store';
import { zapi } from './helpers';

const ZENDESK_KEY = 'ffb896e0-ed29-4ec5-9892-93099dc942f0';
const SCRIPT_SRC = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;

type ZendeskContext = {
  initZendeskApi: (open?: boolean) => void;
};

const zendeskContext = createContext<ZendeskContext | null>(null);

let inited = false;

export const ZendeskProvider = ({ children }: PropsWithChildren) => {
  const { state, setState, setIsOpened, setUnread } = useZendeskStore();
  const [getZendeskToken] = useGetZendeskTokenLazyQuery();

  const initZendeskApi = async (openAfterInit?: boolean) => {
    if (state !== 'none' || inited) {
      return;
    }

    inited = true;

    setState('loading');

    const result = await getZendeskToken();
    const token = result.data?.getZendeskToken.token;

    if (!token) {
      setState('error');
      throw new Error(`Invalid zendesk token: ${token}`);
    }

    await loadScript({ src: SCRIPT_SRC, async: true, id: 'ze-snippet' });

    zapi('messenger:on', 'open', () => setIsOpened(true));
    zapi('messenger:on', 'close', () => setIsOpened(false));
    zapi('messenger:on', 'unreadMessages', (count: number) => setUnread(count));

    zapi('messenger', 'loginUser', (callback: (t: string) => void) => {
      callback(token);
      setState('ready');
    });

    if (openAfterInit) {
      zapi('messenger', 'open');
    }
  };

  return <zendeskContext.Provider value={{ initZendeskApi }}>{children}</zendeskContext.Provider>;
};

export const useZendeskContext = () => useContext(zendeskContext);
