import { TMigration } from '@/shared/lib/migrator/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';

type PrevAssetType = AssetDto & {
  'add-new'?: Record<string, unknown> | undefined;
};

const up = (store: Map<CollectionName, SecureJsonBase>) => {
  const assets: SecureJsonBase<AssetDto> | undefined = store.get(CollectionName.ASSETS);

  if (assets) {
    for (const [, item] of (assets?.items as Map<string, { decryptedData: PrevAssetType }>) || []) {
      delete item.decryptedData['add-new'];
    }
  }
};

export const migration008: TMigration = {
  version: '0.0.8',
  up,
};
