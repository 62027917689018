import { UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';

import { separator } from '@/shared/lib/hooks/use-form-render-old/constants';
import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';
import { TimeDurationUnitsEnum } from '@/shared/lib/sj-orm/models/form/question-option.dto';
import { QuestionResponseDto } from '@/shared/lib/sj-orm/models/form/response/question-response.dto';

export type UseFormRenderInputType = {
  id: DtoId;
  onSubmit?: (data: QuestionResponseDto<unknown>[]) => void;
  submitButtonText?: string;
};

export const InputsWithPlaceholder = [
  QuestionType.URL,
  QuestionType.TEXT,
  QuestionType.NUMBER,
  QuestionType.EMAIL,
];

type DefaultInputType =
  | QuestionType.URL
  | QuestionType.TEXT
  | QuestionType.EMAIL
  | QuestionType.NUMBER;

export const isDefaultInput = (type: QuestionType): type is DefaultInputType =>
  InputsWithPlaceholder.includes(type);

export const DefaultInputToInputType: Record<DefaultInputType, string> = {
  [QuestionType.URL]: 'url',
  [QuestionType.TEXT]: 'text',
  [QuestionType.NUMBER]: 'number',
  [QuestionType.EMAIL]: 'email',
};

export type ReturnedTypesDefault =
  | boolean
  | string
  | number
  | KeyString
  | KeyString<TimeDurationUnitsEnum>
  | Array<string>
  | UploadChangeParam<UploadFile>;
export type ReturnedTypes<T = ReturnedTypesDefault> = T;

export type KeyString<T = string> = { key: T };

const ReturnedArrayTypes = [
  QuestionType.CURRENCY_AMOUNT,
  QuestionType.TIME_DURATION,
  QuestionType.PICK_ARRAY_OF_DTO,
  QuestionType.PHONE_NUMBER,
];

export const isArrayReturnedByType = (
  type: QuestionType,
): type is
  | QuestionType.PICK_ARRAY_OF_DTO
  | QuestionType.CURRENCY_AMOUNT
  | QuestionType.PHONE_NUMBER
  | QuestionType.TIME_DURATION => ReturnedArrayTypes.includes(type);

export type ArrayReturnedTypes = string | KeyString | KeyString<TimeDurationUnitsEnum>;

export type ReturnedTypeMap = {
  [QuestionType.URL]: string;
  [QuestionType.TEXT]: string;
  [QuestionType.PHONE_NUMBER]: [string | KeyString, string | KeyString];
  [QuestionType.CURRENCY_AMOUNT]: [string | KeyString, string | KeyString];
  [QuestionType.TEXT_MULTILINE]: string;
  [QuestionType.TIME_DURATION]: [
    string | KeyString<TimeDurationUnitsEnum>,
    string | KeyString<TimeDurationUnitsEnum>,
  ];
  [QuestionType.DATE_TIME]: string;
  [QuestionType.NUMBER]: number;
  [QuestionType.FILE_UPLOAD]: UploadChangeParam;
  [QuestionType.CHECKBOX]: boolean;
  [QuestionType.RADIO_BUTTON]: boolean;
  [QuestionType.SELECT_FROM_LIST]: KeyString;
  [QuestionType.PICK_ARRAY_OF_DTO]: Array<string>;
  [QuestionType.EMAIL]: string;
  [QuestionType.CURRENCY_CALCULATOR]: string;
  [QuestionType.RENDER_NEW_BUTTON]: false;
};

export type FormItemKey = `${QuestionType}${typeof separator}${string}${typeof separator}${string}`;

export type FormItemsType = {
  [key: FormItemKey]: ReturnedTypes;
};
