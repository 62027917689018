import { NotificationType } from '@/shared/lib/sj-orm/models/notification.dto';

export const NotificationTypeToText: Record<NotificationType, string> = {
  [NotificationType.TEST_SCENARIO_LAUNCHED]: 'Transfer Algorithm Launched in Test Mode',
  [NotificationType.TEST_SCENARIO_ACCEPTED]: 'Test Transfer Algorithm Accepted by the Next Owner',
  [NotificationType.SCENARIO_CREATED]: 'Transfer Algorithm Created',
  [NotificationType.SCENARIO_ACTIVATED]: 'Transfer Algorithm Activated ',
  [NotificationType.SCENARIO_RESOLVED]: 'Transfer Algorithm Resolved ',
  [NotificationType.SCENARIO_DEACTIVATED]: 'Transfer Algorithm Turned Off ',
  [NotificationType.TRIGGER_SCENARIO_INFO]: 'Transfer Algorithm Trigger Activated',
  [NotificationType.SCENARIO_EXECUTED]: 'Transfer Algorithm Completed',

  [NotificationType.BENEFICIARY_CREATED]: 'Next Owner Profile Created',
  [NotificationType.BENEFICIARY_UPDATED]: 'Next Owner Profile Updated',
  [NotificationType.BENEFICIARY_DELETED]: 'Next Owner Profile Deleted',
  [NotificationType.BENEFICIARY_SENT_INVITATION]: 'Invitation Sent to Next Owner',
  [NotificationType.BENEFICIARY_ACCEPT_INVITATION]: 'Next Owner Accepted Invitation',

  [NotificationType.PASSWORD_CHANGE]: 'Password Changed',
  [NotificationType.CHECK_PLAN]: 'Check plan',
  [NotificationType.DATA_TRANSFERRED_TO_MY_HUB]: 'Data Uploaded to MyHub',
  [NotificationType.MY_HUB_CREATED]: 'MyHub is ready',

  [NotificationType.SENT_REQUEST_TO_ASSISTANT]: 'Request Sent to Assistant',
  [NotificationType.ASSISTANT_FILLED_FORM]: 'Assistant Completed Filling In',

  [NotificationType.DOCUMENT_CREATED]: 'Document Created',
  [NotificationType.DOCUMENT_DELETED]: 'Document Deleted',
  [NotificationType.FILED_ADDED_TO_INBOX]: 'New files added to Inbox',
  [NotificationType.FILED_DELETED_FROM_INBOX]: 'Files deleted from Inbox',
  [NotificationType.DOCUMENTS_BY_ASSISTANT]: 'Documents by assistant',

  [NotificationType.CONTACT_CREATED]: 'Contact Created',
  [NotificationType.CONTACT_DELETED]: 'Contact Deleted',

  [NotificationType.ASSET_DELETED]: 'Asset Deleted',
  [NotificationType.ASSET_CREATED]: 'Asset Created',
  [NotificationType.ASSET_UPDATED]: 'Asset Data Updated',
};
