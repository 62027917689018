// import { OwnIdDocumentForm } from '@/services/forms/documents/own-id';
import { BirthCertificateForm } from '@/shared/lib/forms/documents/personal-IDs/birth-certificate';
import { DriverLicenseForm } from '@/shared/lib/forms/documents/personal-IDs/driver-license';
import { EducationForm } from '@/shared/lib/forms/documents/personal-IDs/education';
import { FreeBlockForm } from '@/shared/lib/forms/documents/personal-IDs/free-block';
import { InternationalDriverLicenseForm } from '@/shared/lib/forms/documents/personal-IDs/international-driver-license';
import { MedicalIDForm } from '@/shared/lib/forms/documents/personal-IDs/medical-ID';
import { MilitaryIDForm } from '@/shared/lib/forms/documents/personal-IDs/military-ID';
import { NationalIDForm } from '@/shared/lib/forms/documents/personal-IDs/national-ID';
import { PassportForm } from '@/shared/lib/forms/documents/personal-IDs/passport';
import { PersonalContactsForm } from '@/shared/lib/forms/documents/personal-IDs/personal-contacts';
import { ResidencePermitForm } from '@/shared/lib/forms/documents/personal-IDs/residence-permit';
import { SocialSecurityForm } from '@/shared/lib/forms/documents/personal-IDs/social-security';
import { SubstanceForm } from '@/shared/lib/forms/documents/personal-IDs/substance';
import { TaxIDForm } from '@/shared/lib/forms/documents/personal-IDs/tax-ID';
import { UserDetailsForm } from '@/shared/lib/forms/documents/personal-IDs/user-details';
import { BilateralForm } from '@/shared/lib/forms/documents/private/bilateral';
import { MultilateralForm } from '@/shared/lib/forms/documents/private/multilateral';
import { UnilateralForm } from '@/shared/lib/forms/documents/private/unilateral';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { PersonalIdentifierType } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentType,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';

export type DocumentCategory = PrivateDocumentType | PersonalIdentifierType;

export const DocumentCategoryToText: Record<DocumentCategory, string> = {
  [PrivateDocumentType.UNILATERAL]: 'documents:enums.docCategory.unilateral',
  [PrivateDocumentType.BILATERAL]: 'documents:enums.docCategory.bilateral',
  [PrivateDocumentType.MULTILATERAL]: 'documents:enums.docCategory.multilateral',
  [PersonalIdentifierType.DRIVER_LICENSE]: 'documents:enums.docCategory.driverLicense',
  [PersonalIdentifierType.PASSPORT]: 'documents:enums.docCategory.passport',
  // [PersonalIdentifierType.OWN_ID]: 'documents:enums.docCategory.ownId',
  [PersonalIdentifierType.BIRTH_CERTIFICATE]: 'documents:enums.docCategory.birthCertificate',
  [PersonalIdentifierType.EDUCATION]: 'documents:enums.docCategory.education',
  [PersonalIdentifierType.FREE_BLOCK]: 'documents:enums.docCategory.freeBlock',
  [PersonalIdentifierType.INTERNATIONAL_DRIVER_LICENSE]:
    'documents:enums.docCategory.internationalDriverLicense',
  [PersonalIdentifierType.MEDICAL_ID]: 'documents:enums.docCategory.medicalId',
  [PersonalIdentifierType.MILITARY_ID]: 'documents:enums.docCategory.militaryId',
  [PersonalIdentifierType.NATIONAL_ID]: 'documents:enums.docCategory.nationalId',
  [PersonalIdentifierType.PERSONAL_CONTACTS]: 'documents:enums.docCategory.personalContacts',
  [PersonalIdentifierType.RESIDENCE_PERMIT]: 'documents:enums.docCategory.residencePermit',
  [PersonalIdentifierType.SOCIAL_SECURITY]: 'documents:enums.docCategory.socialSecurity',
  [PersonalIdentifierType.SUBSTANCE]: 'documents:enums.docCategory.substance',
  [PersonalIdentifierType.TAX_ID]: 'documents:enums.docCategory.taxId',
  [PersonalIdentifierType.USER_DETAILS]: 'documents:enums.docCategory.userDetails',
};

export const PrivateDocumentCategoryToText: Record<PrivateDocumentCategory, string> = {
  [PrivateDocumentCategory.PRIVATE_AGREEMENTS]:
    'documents:enums.privateDocCategory.privateAgreements',
  [PrivateDocumentCategory.PUBLIC_AGREEMENTS]:
    'documents:enums.privateDocCategory.publicAgreements',
  [PrivateDocumentCategory.INFORMATION_SHEETS]:
    'documents:enums.privateDocCategory.informationSheets',
  [PrivateDocumentCategory.BANK_DOCUMENTS]: 'documents:enums.privateDocCategory.bankDocuments',
  [PrivateDocumentCategory.PAYMENT_DOCUMENTS]:
    'documents:enums.privateDocCategory.paymentDocuments',
  // [PersonalIdentifierType.OWN_ID]: 'documents:enums.docCategory.ownId',
  [PrivateDocumentCategory.POWERS]: 'documents:enums.privateDocCategory.powers',
  [PrivateDocumentCategory.CORPORATE_DOCUMENTS]:
    'documents:enums.privateDocCategory.corporateDocuments',
  [PrivateDocumentCategory.FAMILY_DOCUMENTS]: 'documents:enums.privateDocCategory.familyDocuments',
  [PrivateDocumentCategory.OTHER]: 'documents:enums.privateDocCategory.other',
};

export const isPersonalIdentifier = (category: string) =>
  [
    PersonalIdentifierType.DRIVER_LICENSE,
    PersonalIdentifierType.PASSPORT,
    // PersonalIdentifierType.OWN_ID,
    PersonalIdentifierType.BIRTH_CERTIFICATE,
    PersonalIdentifierType.EDUCATION,
    PersonalIdentifierType.FREE_BLOCK,
    PersonalIdentifierType.INTERNATIONAL_DRIVER_LICENSE,
    PersonalIdentifierType.MEDICAL_ID,
    PersonalIdentifierType.MILITARY_ID,
    PersonalIdentifierType.NATIONAL_ID,
    PersonalIdentifierType.PERSONAL_CONTACTS,
    PersonalIdentifierType.RESIDENCE_PERMIT,
    PersonalIdentifierType.SOCIAL_SECURITY,
    PersonalIdentifierType.SUBSTANCE,
    PersonalIdentifierType.TAX_ID,
    PersonalIdentifierType.USER_DETAILS,
  ].includes(category as PersonalIdentifierType);

type OmitedBaseDto = Omit<BaseDto, 'id'>;

export const DocumentsForms: Record<
  DocumentCategory,
  Array<TDtoWrapper<OmitedBaseDto, NonNullable<unknown>>> | null
> = {
  [PrivateDocumentType.UNILATERAL]: [UnilateralForm],
  [PrivateDocumentType.BILATERAL]: [BilateralForm],
  [PrivateDocumentType.MULTILATERAL]: [MultilateralForm],
  [PersonalIdentifierType.USER_DETAILS]: [UserDetailsForm],
  [PersonalIdentifierType.PASSPORT]: [PassportForm],
  [PersonalIdentifierType.NATIONAL_ID]: [NationalIDForm],
  [PersonalIdentifierType.RESIDENCE_PERMIT]: [ResidencePermitForm],
  [PersonalIdentifierType.SUBSTANCE]: [SubstanceForm],
  [PersonalIdentifierType.TAX_ID]: [TaxIDForm],
  [PersonalIdentifierType.BIRTH_CERTIFICATE]: [BirthCertificateForm],
  [PersonalIdentifierType.DRIVER_LICENSE]: [DriverLicenseForm],
  [PersonalIdentifierType.INTERNATIONAL_DRIVER_LICENSE]: [InternationalDriverLicenseForm],
  [PersonalIdentifierType.EDUCATION]: [EducationForm],
  [PersonalIdentifierType.MEDICAL_ID]: [MedicalIDForm],
  [PersonalIdentifierType.MILITARY_ID]: [MilitaryIDForm],
  [PersonalIdentifierType.PERSONAL_CONTACTS]: [PersonalContactsForm],
  [PersonalIdentifierType.SOCIAL_SECURITY]: [SocialSecurityForm],
  [PersonalIdentifierType.FREE_BLOCK]: [FreeBlockForm],

  // [PersonalIdentifierType.OWN_ID]: [OwnIdDocumentForm],
};
