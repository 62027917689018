import { ReactElement } from 'react';
import NextHead from 'next/head';

import appInfo from '../../../../app-info.json';

type PropertiesType = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
};

const defaults: PropertiesType = {
  title: `${appInfo.name}`,
  description: `${appInfo.name} App`,
  image: `${process.env.NEXT_PUBLIC_SELF_URL_BASE}/api/logo?titleFirst=${appInfo.name}&titleSecond=`,
  url: process.env.NEXT_PUBLIC_SELF_URL_BASE,
};

export const Meta = (_properties: PropertiesType): ReactElement => {
  const properties: PropertiesType = { ...defaults, ..._properties };

  return (
    <NextHead>
      <title>{properties.title}</title>

      <meta name="description" content="Owner.One App" />
      <meta name="description" content={properties.description} key={'desc'} />
      <meta name="theme-color" content="#000000" />
      <meta name="viewport" content="width=device-width, user-scalable=no" />
      <meta name="application-name" content="Owner.One Make your wealth transfer-ready" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />

      {/*<!-- MS -->*/}
      <meta name="msapplication-config" content="/icons/browserconfig.xml" />
      <meta name="msapplication-TileColor" content="#2B5797" />
      <meta name="msapplication-tap-highlight" content="no" />

      {/*<!-- Google / Search Engine Tags -->*/}
      <meta itemProp="name" content={properties.title} />
      <meta itemProp="description" content={properties.description} />
      <meta itemProp="image" content={properties.image} />

      {/*<!-- Facebook Meta Tags -->*/}
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content={properties.title} />
      <meta property="og:title" content={properties.title} />
      <meta property="og:description" content={properties.description} />
      <meta property="og:image" content={properties.image} />
      <meta property="og:url" content={properties.url} />
      <meta property="og:type" content="website" />
      <meta property="og:image:width" content="467" />
      <meta property="og:image:height" content="263" />

      {/*<!-- Twitter Meta Tags -->*/}
      <meta name="twitter:title" content={properties.title} key={'ttitle'} />
      <meta name="twitter:description" content={properties.description} key={'tdesc'} />
      <meta name="twitter:image" content={properties.image} key={'timg'} />
      <meta name="twitter:card" content="summary_large_image" key={'tcard'} />
      <meta name="twitter:site" content={properties.url} />
      <meta name="twitter:creator" content={properties.url} />

      {/*<!-- apple-mobile-web-app Meta Tags -->*/}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="Owner.One" />
    </NextHead>
  );
};
