import { TMigration } from '@/shared/lib/migrator/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';

import { SJDatabase } from '../../sj-orm/core';
import { BaseDto } from '../../sj-orm/models/base.dto';

const up = (store: Map<CollectionName, SecureJsonBase>) => {
  const contacts: SecureJsonBase<AssetDto> | undefined = store.get(CollectionName.CONTACTS);

  if (contacts) {
    for (const [, item] of (contacts?.items as Map<string, { decryptedData: BaseDto }>) || []) {
      if (item.decryptedData.id === '') {
        item.decryptedData.id = SJDatabase.generateId(CollectionName.CONTACTS);
      }
    }
  }
};

export const migration0010: TMigration = {
  version: '0.0.10',
  up,
};
