import { ComponentProps, FC, PropsWithChildren } from 'react';
import { Typography, TypographyProps } from 'antd';

const { Title: AntTitle } = Typography;

type TitleProps = PropsWithChildren<Partial<ComponentProps<TypographyProps['Title']>>>;

export const Title: FC<TitleProps> = ({ style, children, ...props }) => (
  <AntTitle style={{ ...style, fontFamily: 'Argent CF' }} {...props}>
    {children}
  </AntTitle>
);
