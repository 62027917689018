import { type FC, MouseEvent, ReactNode } from 'react';
import { Drawer, Modal, ModalProps } from 'antd';
import { styled } from 'styled-components';

import { useIsDesktop } from '@/shared/hooks/use-is-desktop';

type StyleProps = { minHeight?: string; maxHeight?: string };

type ResponsiveModalProps = ModalProps & StyleProps & { drawerHeight?: string };

export const ResponsiveModal: FC<ResponsiveModalProps> = ({ footer, drawerHeight, ...props }) => {
  const isDesktop = useIsDesktop();
  return isDesktop ? (
    <StyledModal {...props} footer={footer} />
  ) : (
    <StyledDrawer
      {...props}
      onClose={(e) => props.onCancel?.(e as MouseEvent<HTMLButtonElement>)}
      placement="bottom"
      height={drawerHeight || '90vh'}
      footer={footer as ReactNode}
    />
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    border-bottom: 0 !important;
  }

  .ant-drawer-footer {
    border-top: 0 !important;
  }

  .ant-drawer-body {
    padding: 0 !important;
  }
`;

const StyledModal = styled(Modal)<StyleProps>`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    min-height: ${({ minHeight }) => minHeight ?? '65vh'};
    max-height: ${({ maxHeight }) => maxHeight ?? '65vh'};
    overflow-y: auto;
  }

  @media screen and (max-height: 800px) {
    top: 40px !important;

    .ant-modal-body {
      min-height: 70vh;
      max-height: 70vh;
    }
  }
`;
