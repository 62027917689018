import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';

import { IconType } from './types';

export const Icon = ({ icon, cursorPointer, fontSize, color, onClick, ...props }: IconType) => {
  return (
    <Div
      $cursorPointer={cursorPointer}
      fontSize={fontSize}
      color={color}
      onClick={onClick}
      {...props}
    >
      {icon}
    </Div>
  );
};

const Div = styled.div<{
  $cursorPointer?: boolean;
  fontSize?: number;
  color?: string;
}>`
  font-family: 'Material Icons', sans-serif;
  font-weight: 700;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '20px')};
  color: ${({ color }) => (color ? color : COLORS.colorIcon)};
  cursor: ${({ $cursorPointer }) => ($cursorPointer ? 'pointer' : '')};

  &:hover {
    color: ${({ color }) => (color ? color : COLORS.colorIconHover)};
  }
`;
