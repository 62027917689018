import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import {
  ActivityList,
  ActivityType,
  AssetCategory,
  HighMediumLowList,
  HighMediumLowType,
  OwnershipList,
  OwnershipType,
} from '@/shared/lib/sj-orm/models/asset.dto';
import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { ContactDto, ContactType } from '@/shared/lib/sj-orm/models/contact.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { PersonalIdentifierType } from '../../sj-orm/models/document/personal-identifier.document.dto';
import { PrivateDocumentCategory } from '../../sj-orm/models/document/private.document.dto';

export enum LegalEntityContactAddressType {
  LEGAL = 'legal',
  OTHER = 'other',
}
//   { label: '', key: },
const LegalEntityContactAddressTypesLst = [
  {
    label: 'forms_contacts:legalEntity.enums.addressType.legal',
    key: LegalEntityContactAddressType.LEGAL,
  },
  {
    label: 'forms_contacts:legalEntity.enums.addressType.other',
    key: LegalEntityContactAddressType.OTHER,
  },
];

type LegalEntityContactDataPartType = Omit<ContactDto, 'id' | 'type'> & {
  nickname: string;
  selectedCountry: string;
  phone?: number;
  email?: string;
  //address
  addressType?: LegalEntityContactAddressType;
  address?: string;
  fullLegalName?: string;
  activityType?: ActivityType;
  trustLevel?: HighMediumLowType;
  contactPerson?: DtoId; // individual contact
  // asset info
  associatedWithAnAsset?: boolean;
  assetId?: DtoId;
  assetCommentary?: string;
  // company info
  legalForm?: string;
  businessType?: string;
  dateOfIncorporation?: Date;
  taxNumber?: string;
  taxNumberIssuer?: string;
  lei?: string;
  // company owner info
  ownershipType?: OwnershipType;
  owner?: DtoId;
  ownerDocument: DtoId; // personal id
  ownerOtherDocument: DtoId; // private
  documents: DtoId[]; // private
  commentary: string;
};

type LegalEntityContactHelpPartType = {
  nickname: QuestionType.TEXT;
  selectedCountry: QuestionType.SELECT_FROM_LIST;
  phone?: QuestionType.PHONE_NUMBER;
  email?: QuestionType.EMAIL;
  //address
  addressType?: QuestionType.SELECT_FROM_LIST;
  address?: QuestionType.TEXT;
  fullLegalName?: QuestionType.TEXT;
  activityType?: QuestionType.SELECT_FROM_LIST;
  trustLevel?: QuestionType.SELECT_FROM_LIST;
  contactPerson?: QuestionType.PICK_ARRAY_OF_DTO; // individual contact
  // asset info
  associatedWithAnAsset?: QuestionType.CHECKBOX;
  assetId?: QuestionType.PICK_ARRAY_OF_DTO;
  assetCommentary?: QuestionType.TEXT;
  // company info
  legalForm?: QuestionType.TEXT;
  businessType?: QuestionType.TEXT;
  dateOfIncorporation?: QuestionType.DATE_TIME;
  taxNumber?: QuestionType.TEXT;
  taxNumberIssuer?: QuestionType.TEXT;
  lei?: QuestionType.TEXT;
  // company owner info
  ownershipType?: QuestionType.SELECT_FROM_LIST;
  owner?: QuestionType.PICK_ARRAY_OF_DTO;
  ownerDocument: QuestionType.PICK_ARRAY_OF_DTO; // personal id
  ownerOtherDocument: QuestionType.PICK_ARRAY_OF_DTO; // private
  documents: QuestionType.PICK_ARRAY_OF_DTO; // private
  commentary: QuestionType.TEXT_MULTILINE;
};

type LegalEntityContactFormType = TDtoWrapper<
  LegalEntityContactDataPartType,
  LegalEntityContactHelpPartType
>;

const yupRequiredField = yup.string().required('common:thisIsRequiredField');

const LegalEntityContactForm: LegalEntityContactFormType = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:common.inputs.nickname.label',
    required: true,
    value: '',
    yupScheme: yupRequiredField,
  },
  selectedCountry: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:common.inputs.selectedCountry.label',
    required: true,
    value: '',
    items: CountriesList,
    withSearch: true,
    yupScheme: yupRequiredField,
  },
  fullLegalName: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.fullLegalName.label',
  },
  phone: {
    type: QuestionType.PHONE_NUMBER,
    label: 'forms_contacts:common.inputs.phoneNumber.label',
  },
  email: {
    type: QuestionType.EMAIL,
    label: 'forms_contacts:common.inputs.email.label',
  },
  //address
  addressType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:legalEntity.inputs.addressType.label',
    items: LegalEntityContactAddressTypesLst,
  },
  address: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:common.inputs.address.label',
  },
  activityType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:legalEntity.inputs.activityType.label',
    items: ActivityList,
  },
  trustLevel: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:legalEntity.inputs.trustLevel.label',
    items: HighMediumLowList,
  },
  contactPerson: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // individual contact
    label: 'forms_contacts:legalEntity.inputs.contactPerson.label',
    items: 'contacts',
    dtoType: ContactType.BANK,
  },
  // asset info
  associatedWithAnAsset: {
    type: QuestionType.CHECKBOX,
    label: 'forms_contacts:legalEntity.inputs.associatedWithAnAsset.label',
  },
  assetId: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:legalEntity.inputs.assetId.label',
    items: 'assets',
    showIf: {
      associatedWithAnAsset: {
        value: true,
      },
    },
    dtoType: AssetCategory.BANK_CELLS,
  },
  assetCommentary: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.assetCommentary.label',
    showIf: {
      associatedWithAnAsset: {
        value: true,
      },
    },
  },
  // company info
  legalForm: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.legalForm.label',
  },
  businessType: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.businessType.label',
  },
  dateOfIncorporation: {
    type: QuestionType.DATE_TIME,
    label: 'forms_contacts:legalEntity.inputs.dateOfIncorporation.label',
  },
  taxNumber: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.taxNumber.label',
  },
  taxNumberIssuer: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.taxNumberIssuer.label',
  },
  lei: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.lei.label',
  },
  // company owner info
  ownershipType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:legalEntity.inputs.ownershipType.label',
    items: OwnershipList,
  },
  owner: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:legalEntity.inputs.owner.label',
    items: 'personalIdentifiersDocuments',
    dtoType: PersonalIdentifierType.BIRTH_CERTIFICATE,
  },
  ownerDocument: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // personal id,
    label: 'forms_contacts:legalEntity.inputs.ownerDocument.label',
    items: 'personalIdentifiersDocuments',
    dtoType: PersonalIdentifierType.BIRTH_CERTIFICATE,
  },
  ownerOtherDocument: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // private,
    label: 'forms_contacts:legalEntity.inputs.ownerOtherDocument.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  documents: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // private,
    label: 'forms_contacts:legalEntity.inputs.documents.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  commentary: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_contacts:legalEntity.inputs.comment.label',
  },
};

export { LegalEntityContactForm };
