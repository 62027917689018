import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum BasicAssetType {
  commodity = 'commodity ',
  equity = 'equity',
  currency = 'currency',
  index = 'index',
  interestRate = 'interestRate',
  futuresContract = 'futuresContract',
  other = 'other',
}

const BasicAssetList = [
  {
    label: 'forms_assets:futures.enums.basicAssetType.commodity',
    key: BasicAssetType.commodity,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.equity',
    key: BasicAssetType.equity,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.currency',
    key: BasicAssetType.currency,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.index',
    key: BasicAssetType.index,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.interestRate',
    key: BasicAssetType.interestRate,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.futuresContract',
    key: BasicAssetType.futuresContract,
  },
  {
    label: 'forms_assets:futures.enums.basicAssetType.other',
    key: BasicAssetType.other,
  },
];

export enum ForwardsType {
  deliveryBased = 'deliveryBased',
  cashSettled = 'cashSettled',
}

const ForwardsList = [
  {
    label: 'forms_assets:forwards.enums.type.deliveryBased',
    key: ForwardsType.deliveryBased,
  },
  {
    label: 'forms_assets:forwards.enums.type.cashSettled',
    key: ForwardsType.cashSettled,
  },
];

export enum SellerType {
  legalEntity = 'legalEntity',
  person = 'person',
}

const SellerList = [
  {
    label: 'forms_assets:forwards.enums.sellerType.legalEntity',
    key: SellerType.legalEntity,
  },
  {
    label: 'forms_assets:forwards.enums.sellerType.person',
    key: SellerType.person,
  },
];

type ForwardsPart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  type?: ForwardsType;
  purchaseValue: number;
  basicAssetName: string;
  basicAssetType?: BasicAssetType;
  otherBasicAssetType: string;
  basicAssetAmount?: number;
  basicAssetHolder?: string;
};

type ForwardsPart2 = Omit<BaseDto, 'id'> & {
  basicPrice?: number;
  expirationDate?: number;
  seller?: SellerType;
  legalEntityName?: string;
  personFullName?: string;
  insurance?: boolean;
  dealReserves?: boolean;
  dealReservesData?: string;
  depositoryStatements?: DtoId[];
  strategy?: string;
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type ForwardsHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.TEXT;
  type?: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  basicAssetName: QuestionType.TEXT;
  basicAssetType: QuestionType.SELECT_FROM_LIST;
  otherBasicAssetType: QuestionType.TEXT;
  basicAssetAmount: QuestionType.NUMBER;
  basicAssetHolder: QuestionType.TEXT;
};

type ForwardsHelpPart2 = {
  basicPrice?: QuestionType.CURRENCY_CALCULATOR;
  expirationDate?: QuestionType.DATE_TIME;
  seller?: QuestionType.SELECT_FROM_LIST;
  legalEntityName?: QuestionType.TEXT;
  personFullName?: QuestionType.TEXT;
  insurance?: QuestionType.CHECKBOX;
  dealReserves?: QuestionType.CHECKBOX;
  dealReservesData?: QuestionType.TEXT;
  depositoryStatements?: QuestionType.PICK_ARRAY_OF_DTO;
  strategy?: QuestionType.TEXT;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type ForwardsFormType = [
  ForwardsFormPart1Type,
  ForwardsFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

type ForwardsFormPart1Type = TDtoWrapper<ForwardsPart1, ForwardsHelpPart1>;
type ForwardsFormPart2Type = TDtoWrapper<ForwardsPart2, ForwardsHelpPart2>;

const ForwardsFormPart1: ForwardsFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup.string().required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:forwards.inputs.type.label',
    tooltip: 'forms_assets:forwards.inputs.type.tooltip',
    value: ForwardsType.cashSettled,
    items: ForwardsList,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:forwards.inputs.purchaseValue.label',
    tooltip: 'forms_assets:forwards.inputs.purchaseValue.tooltip',
    equivalentValueTooltip: 'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  basicAssetType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:forwards.inputs.basicAssetType.label',
    tooltip: 'forms_assets:forwards.inputs.basicAssetType.tooltip',
    items: BasicAssetList,
    // required: true,
    value: BasicAssetType.commodity,
  },
  otherBasicAssetType: {
    type: QuestionType.TEXT,
    label: 'forms_assets:forwards.inputs.otherBasicAssetType.label',
    required: true,
    value: '',
    showIf: {
      basicAssetType: {
        value: BasicAssetType.other,
      },
    },
  },
  basicAssetName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:forwards.inputs.basicAssetName.label',
    // required: true,
    value: '',
  },
  basicAssetAmount: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:forwards.inputs.basicAssetAmount.label',
    // required: true,
    value: 0,
  },
  basicAssetHolder: {
    type: QuestionType.TEXT,
    label: 'forms_assets:forwards.inputs.basicAssetHolder.label',
    tooltip: 'forms_assets:forwards.inputs.basicAssetHolder.tooltip',
    // required: true,
    value: '',
  },
};

const ForwardsFormPart2: ForwardsFormPart2Type = {
  basicPrice: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:forwards.inputs.basicPrice.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip: 'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 1,
  },
  expirationDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:forwards.inputs.expirationDate.label',
    tooltip: 'forms_assets:forwards.inputs.expirationDate.tooltip',
    order: 2,
  },
  seller: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:forwards.inputs.seller.label',
    order: 3,
    items: SellerList,
  },
  legalEntityName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:otcOptions.inputs.legalEntityName.label',
    order: 4,
    showIf: {
      seller: {
        value: SellerType.legalEntity,
      },
    },
  },
  personFullName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:otcOptions.inputs.personFullName.label',
    order: 5,
    showIf: {
      seller: {
        value: SellerType.person,
      },
    },
  },
  insurance: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:forwards.inputs.insurance.label',
    order: 6,
  },
  dealReserves: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:forwards.inputs.dealReserves.label',
    tooltip: 'forms_assets:forwards.inputs.dealReserves.tooltip',
    order: 7,
  },
  dealReservesData: {
    type: QuestionType.TEXT,
    label: 'forms_assets:forwards.inputs.dealReservesData.label',
    order: 8,
    showIf: {
      dealReserves: {
        value: true,
      },
    },
  },
  strategy: {
    type: QuestionType.TEXT,
    label: 'forms_assets:forwards.inputs.strategy.label',
    tooltip: 'forms_assets:forwards.inputs.strategy.tooltip',
    order: 9,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory === PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const ForwardsForm: ForwardsFormType = [
  ForwardsFormPart1,
  ForwardsFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgent',
    'taxAgentWithBroker',
    'publicFundTaxAgent',
    'taxAgentOther',
    'taxAgentWithBrokerOther',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];
export { ForwardsForm };
