import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { ContactDto, ContactType } from '@/shared/lib/sj-orm/models/contact.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export type BrokerageAccount = BrokerageAccountFormPart1Type &
  BrokerageAccountFormPart2Type &
  AssetsOwnershipPartType &
  AssetsStatisticsPartType;

export enum BrokerageAccountType {
  primary = 'primary',
  otc = 'otc',
  fixed = 'fixed',
}

const BrokerageAccountList = [
  {
    label: 'forms_assets:brokerageAccount.enums.type.primary',
    key: BrokerageAccountType.primary,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.type.otc',
    key: BrokerageAccountType.otc,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.type.fixed',
    key: BrokerageAccountType.fixed,
  },
];

export enum KeyAssetsType {
  structureProduct = 'structureProduct',
  substituteBonds = 'substituteBonds',
  eurobonds = 'eurobonds',
  corporateBonds = 'corporateBonds',
  federalLoanBonds = 'federalLoanBonds',
  bonds = 'bonds',
  commonShares = 'commonShares',
  preferredShares = 'preferredShares',
  investmentFunds = 'investmentFunds',
  options = 'options',
  futures = 'futures',
  currencyPairs = 'currencyPairs',
  other = 'other',
}

const KeyAssetsList = [
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.structureProduct',
    key: KeyAssetsType.structureProduct,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.substituteBonds',
    key: KeyAssetsType.substituteBonds,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.eurobonds',
    key: KeyAssetsType.eurobonds,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.corporateBonds',
    key: KeyAssetsType.corporateBonds,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.federalLoanBonds',
    key: KeyAssetsType.federalLoanBonds,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.bonds',
    key: KeyAssetsType.bonds,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.commonShares',
    key: KeyAssetsType.commonShares,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.preferredShares',
    key: KeyAssetsType.preferredShares,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.investmentFunds',
    key: KeyAssetsType.investmentFunds,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.options',
    key: KeyAssetsType.options,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.futures',
    key: KeyAssetsType.futures,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.currencyPairs',
    key: KeyAssetsType.currencyPairs,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.keyAssets.other',
    key: KeyAssetsType.other,
  },
];

export enum BlockingReasonType {
  sanctions = 'sanctions',
  unsignedOrders = 'unsignedOrders',
  lackOfFundsForProvision = 'lackOfFundsForProvision',
  other = 'other',
}

const BlockingReasonList = [
  {
    label: 'forms_assets:brokerageAccount.enums.blockingReason.sanctions',
    key: BlockingReasonType.sanctions,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.blockingReason.unsignedOrders',
    key: BlockingReasonType.unsignedOrders,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.blockingReason.lackOfFundsForProvision',
    key: BlockingReasonType.lackOfFundsForProvision,
  },
  {
    label: 'forms_assets:brokerageAccount.enums.blockingReason.other',
    key: BlockingReasonType.other,
  },
];

type BrokerageAccountPart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  type?: BrokerageAccountType;
  purchaseValue: number;
  keyAssets?: KeyAssetsType;
  otherKeyAssets?: string;
  holder?: DtoId[];
};

type BrokerageAccountPart2 = Omit<BaseDto, 'id'> & {
  availableLimit?: number;
  totalBalance?: number; //valueInTheTransactions + availableLimit
  plannedProfitability?: number;
  actualProfitability?: number;
  commissions?: number;
  expenses?: number;
  totalExpenses?: number;
  tradingPlatforms?: string;
  cif?: string;
  iban?: string;
  openingDate?: number;
  marginPosition?: boolean;
  marginPositionAvailableLimit?: string;
  blocking?: boolean;
  blockingReason?: BlockingReasonType;
  otherBlockingReason?: string;
  strategyDescription?: string;
  reportsAndStatements?: DtoId[];
  powersOfAttorney?: DtoId[];
  documents?: DtoId[];
  comments?: string;
};

type BrokerageAccountHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  type?: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  keyAssets: QuestionType.SELECT_FROM_LIST;
  otherKeyAssets: QuestionType.TEXT;
  holder: QuestionType.PICK_ARRAY_OF_DTO;
};
type BrokerageAccountHelpPart2 = {
  availableLimit?: QuestionType.CURRENCY_CALCULATOR;
  totalBalance?: QuestionType.CURRENCY_CALCULATOR; //valueInTheTransactions + availableLimit
  plannedProfitability?: QuestionType.CURRENCY_CALCULATOR;
  actualProfitability?: QuestionType.CURRENCY_CALCULATOR;
  commissions?: QuestionType.NUMBER;
  expenses?: QuestionType.CURRENCY_CALCULATOR;
  totalExpenses?: QuestionType.NUMBER;
  tradingPlatforms?: QuestionType.TEXT;
  cif?: QuestionType.TEXT;
  iban?: QuestionType.TEXT;
  openingDate?: QuestionType.DATE_TIME;
  marginPosition?: QuestionType.CHECKBOX;
  marginPositionAvailableLimit?: QuestionType.TEXT;
  blocking?: QuestionType.CHECKBOX;
  blockingReason: QuestionType.SELECT_FROM_LIST;
  otherBlockingReason?: QuestionType.TEXT;
  strategyDescription?: QuestionType.TEXT;
  reportsAndStatements?: QuestionType.PICK_ARRAY_OF_DTO;
  powersOfAttorney: QuestionType.PICK_ARRAY_OF_DTO;
  documents?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type BrokerageAccountFormType = [
  BrokerageAccountFormPart1Type,
  BrokerageAccountFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

type BrokerageAccountFormPart1Type = TDtoWrapper<BrokerageAccountPart1, BrokerageAccountHelpPart1>;

type BrokerageAccountFormPart2Type = TDtoWrapper<BrokerageAccountPart2, BrokerageAccountHelpPart2>;

const BrokerageAccountFormPart1: BrokerageAccountFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup.string().required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:brokerageAccount.inputs.type.label',
    value: BrokerageAccountType.primary,
    items: BrokerageAccountList,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:brokerageAccount.inputs.investedAssets.label',
    tooltip: 'forms_assets:brokerageAccount.inputs.investedAssets.tooltip',
    equivalentValueTooltip:
      // eslint-disable-next-line sonarjs/no-duplicate-string
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  keyAssets: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:brokerageAccount.inputs.keyAssets.label',
    tooltip: 'forms_assets:brokerageAccount.inputs.keyAssets.tooltip',
    value: KeyAssetsType.bonds,
    // required: true,
    items: KeyAssetsList,
    mode: 'multiple',
  },
  otherKeyAssets: {
    type: QuestionType.TEXT,
    label: 'forms_assets:brokerageAccount.inputs.otherKeyAssets.label',
    required: true,
    value: '',
    showIf: {
      keyAssets: {
        value: KeyAssetsType.other,
      },
    },
  },
  holder: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:brokerageAccount.inputs.holder.label',
    value: [''],
    // required: true,
    items: 'contacts',
    yupScheme: yup.string().required('forms_assets:bankCells.inputs.holder.errors.required'),
    filter: (contact) =>
      [ContactType.BROKER, ContactType.BANK].includes((contact as unknown as ContactDto).type),
    dtoType: ContactType.BANK,
    tooltip:
      'To add any broker to the list, please fill it out in the Contact section and you can choose it from the drop-down',
  },
};

const BrokerageAccountFormPart2: BrokerageAccountFormPart2Type = {
  availableLimit: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:brokerageAccount.inputs.availableLimit.label',
    tooltip: 'forms_assets:brokerageAccount.inputs.availableLimit.tooltip',
    equivalentValueTooltip: 'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 2,
  },
  totalBalance: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:brokerageAccount.inputs.totalBalance.label', //формула
    tooltip: 'forms_assets:brokerageAccount.inputs.totalBalance.tooltip',
    equivalentValueTooltip: 'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 3,
  },
  // commissions: {
  //   type: QuestionType.NUMBER,
  //   label: 'forms_assets:brokerageAccount.inputs.commissions.label',
  // },
  tradingPlatforms: {
    type: QuestionType.TEXT,
    label: 'forms_assets:brokerageAccount.inputs.tradingPlatforms.label',
    tooltip: 'forms_assets:brokerageAccount.inputs.tradingPlatforms.tooltip',
    order: 4,
  },
  expenses: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:brokerageAccount.inputs.expenses.label',
    order: 5,
  },
  cif: {
    type: QuestionType.TEXT,
    label: 'forms_assets:brokerageAccount.inputs.cif.label',
    order: 5,
  },
  iban: {
    type: QuestionType.TEXT,
    label: 'forms_assets:brokerageAccount.inputs.iban.label',
    order: 6,
  },
  openingDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:brokerageAccount.inputs.openingDate.label',
    order: 7,
  },
  marginPosition: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:brokerageAccount.inputs.marginPosition.label',
    tooltip: 'forms_assets:brokerageAccount.inputs.marginPosition.tooltip',
    order: 8,
  },
  marginPositionAvailableLimit: {
    type: QuestionType.TEXT,
    label: 'forms_assets:brokerageAccount.inputs.marginPositionAvailableLimit.label',
    showIf: {
      marginPosition: {
        value: true,
      },
    },
    order: 9,
  },
  blocking: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:brokerageAccount.inputs.blocking.label',
    order: 10,
  },
  blockingReason: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:brokerageAccount.inputs.blockingReason.label',
    items: BlockingReasonList,
    showIf: {
      blocking: {
        value: true,
      },
    },
    order: 11,
  },
  otherBlockingReason: {
    type: QuestionType.TEXT,
    label: 'forms_assets:brokerageAccount.inputs.otherBlockingReason.label',
    showIf: {
      blockingReason: {
        value: BlockingReasonType.other,
      },
    },
    order: 12,
  },
  strategyDescription: {
    type: QuestionType.TEXT,
    label: 'forms_assets:brokerageAccount.inputs.strategyDescription.label',
    order: 13,
  },
  reportsAndStatements: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:brokerageAccount.inputs.reportsAndStatements.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory === PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  documents: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const BrokerageAccountForm: BrokerageAccountFormType = [
  BrokerageAccountFormPart1,
  BrokerageAccountFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgent',
    'taxAgentOther',
    'specificsOfTaxation',
    'profitability',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
  ]).form,
];

export { BrokerageAccountForm };
