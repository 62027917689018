import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { CurrenciesList, CurrenciesType } from '@/shared/lib/forms/asset-form/centralized-wallet';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { PrivateDocumentCategory } from '../../sj-orm/models/document/private.document.dto';

export enum CryptoWalletType {
  hot = 'hot',
  cold = 'cold',
}

const CryptoWalletTypesList = [
  {
    label: 'forms_assets:decentralizedWallet.enums.walletType.hot',
    key: CryptoWalletType.hot,
  },
  {
    label: 'forms_assets:decentralizedWallet.enums.walletType.cold',
    key: CryptoWalletType.cold,
  },
];

// export enum WalletCurrencyType {
//   algorithmicCurrencies = 'algorithmicCurrencies',
//   stableCoins = 'stableCoins',
// }
//
// const WalletCurrencyTypesList = [
//   {
//     label:
//       'forms_assets:decentralizedWallet.enums.currencyType.algorithmicCurrencies',
//     key: WalletCurrencyType.algorithmicCurrencies,
//   },
//   {
//     label: 'forms_assets:decentralizedWallet.enums.currencyType.stableCoins',
//     key: WalletCurrencyType.stableCoins,
//   },
// ];

type DecentralizedWalletPart1Type = Omit<BaseDto, 'id'> & {
  nickname: string;
  country: string;
  assetsInWallet: CurrenciesType;
  purchaseValue: number;
  walletType: CryptoWalletType;
  mnemonicStorageLocation: string;
};

type DecentralizedWalletPart2Type = Omit<BaseDto, 'id'> & {
  officialWalletName?: string;
  deviceStorageLocation?: string;
  loginStorageLocation?: string;
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type DecentralizedWalletHelpPart1Type = {
  nickname: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  assetsInWallet: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  walletType: QuestionType.SELECT_FROM_LIST;
  mnemonicStorageLocation: QuestionType.TEXT;
};

type DecentralizedWalletHelpPart2Type = {
  officialWalletName: QuestionType.TEXT;
  deviceStorageLocation: QuestionType.TEXT;
  loginStorageLocation: QuestionType.TEXT;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type DecentralizedWalletFormPart1Type = TDtoWrapper<
  DecentralizedWalletPart1Type,
  DecentralizedWalletHelpPart1Type
>;

type DecentralizedWalletFormPart2Type = TDtoWrapper<
  DecentralizedWalletPart2Type,
  DecentralizedWalletHelpPart2Type
>;

type DecentralizedWalletFormType = [
  DecentralizedWalletFormPart1Type,
  DecentralizedWalletFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

const DecentralizedWalletFormPart1: DecentralizedWalletFormPart1Type = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup.string().required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  assetsInWallet: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:decentralizedWallet.inputs.assetsInWallet.label',
    value: CurrenciesType.BTC,
    required: true,
    items: CurrenciesList,
    mode: 'multiple',
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:decentralizedWallet.inputs.purchaseValue.label',
    tooltip: 'forms_assets:decentralizedWallet.inputs.purchaseValue.tooltip',
    equivalentValueTooltip: 'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  walletType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:decentralizedWallet.inputs.walletType.label',
    value: CryptoWalletType.hot,
    required: true,
    items: CryptoWalletTypesList,
  },
  mnemonicStorageLocation: {
    type: QuestionType.TEXT,
    label: 'forms_assets:decentralizedWallet.inputs.mnemonicStorageLocation.label',
    tooltip: 'forms_assets:decentralizedWallet.inputs.mnemonicStorageLocation.tooltip',
    value: '',
    // required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:decentralizedWallet.inputs.mnemonicStorageLocation.errors'),
  },
  // cryptoCurrencyNames: {
  //   type: QuestionType.TEXT,
  //   label: 'forms_assets:decentralizedWallet.inputs.cryptoCurrencyNames.label',
  //   value: '',
  //   required: true,
  //   yupScheme: yup
  //     .string()
  //     .required(
  //       'forms_assets:decentralizedWallet.inputs.cryptoCurrencyNames.errors',
  //     ),
  // },
};

const DecentralizedWalletFormPart2: DecentralizedWalletFormPart2Type = {
  officialWalletName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:decentralizedWallet.inputs.officialWalletName.label',
    tooltip: 'forms_assets:decentralizedWallet.inputs.officialWalletName.tooltip',
    order: 1,
  },
  deviceStorageLocation: {
    type: QuestionType.TEXT,
    label: 'forms_assets:decentralizedWallet.inputs.deviceStorageLocation.label',
    order: 2,
  },
  loginStorageLocation: {
    type: QuestionType.TEXT,
    label: 'forms_assets:decentralizedWallet.inputs.loginStorageLocation.label',
    order: 3,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:decentralizedWallet.inputs.documents.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const DecentralizedWalletForm: DecentralizedWalletFormType = [
  DecentralizedWalletFormPart1,
  DecentralizedWalletFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgentWithBrokerOther',
    'taxAgent',
    'taxAgentOther',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { DecentralizedWalletForm };
