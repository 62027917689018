import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum CategoryType {
  a = 'a',
  a1 = 'a1',
  b = 'b',
  b1 = 'b1',
  c = 'c',
  c1 = 'c1',
  d = 'd',
  d1 = 'd1',
  be = 'be',
  ce = 'ce',
  de = 'de',
  c1e = 'c1e',
  d1e = 'd1e',
  m = 'm',
  tm = 'tm',
  tb = 'tb',
  other = 'other',
}

const CategoryList = [
  {
    label: 'A',
    key: CategoryType.a,
  },
  {
    label: 'A1',
    key: CategoryType.a1,
  },
  {
    label: 'B',
    key: CategoryType.b,
  },
  {
    label: 'B1',
    key: CategoryType.b1,
  },
  {
    label: 'C',
    key: CategoryType.c,
  },
  {
    label: 'C1',
    key: CategoryType.c1,
  },
  {
    label: 'D',
    key: CategoryType.d,
  },
  {
    label: 'D1',
    key: CategoryType.d1,
  },
  {
    label: 'BE',
    key: CategoryType.be,
  },
  {
    label: 'CE',
    key: CategoryType.ce,
  },
  {
    label: 'DE',
    key: CategoryType.de,
  },
  {
    label: 'C1E',
    key: CategoryType.c1e,
  },
  {
    label: 'D1E',
    key: CategoryType.d1e,
  },
  {
    label: 'M',
    key: CategoryType.m,
  },
  {
    label: 'Tm',
    key: CategoryType.tm,
  },
  {
    label: 'Tb',
    key: CategoryType.tb,
  },
  {
    label: 'Other',
    key: CategoryType.other,
  },
];

export type DriverLicenseDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  name: string;
  names: string;
  surnames: string;
  country: string;
  number?: string;
  dateOfIssue?: number;
  endDate?: number;
  other?: string;
  category?: CategoryType; //множественный выбор
  otherCategory?: string;
  storageLocation?: string; //гибридная ссылка
  comments?: string;
};

export type DriverLicenseDtoHelpType = {
  name: QuestionType.TEXT;
  names: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  number?: QuestionType.TEXT;
  dateOfIssue?: QuestionType.DATE_TIME;
  endDate?: QuestionType.DATE_TIME;
  other?: QuestionType.TEXT;
  category?: QuestionType.PICK_ARRAY_OF_DTO; //множественный выбор
  otherCategory?: QuestionType.TEXT;
  attachments?: QuestionType.FILE_UPLOAD;
  storageLocation?: QuestionType.TEXT; //гибридная ссылка
  comments?: QuestionType.TEXT_MULTILINE;
};

type DriverLicenseFormType = TDtoWrapper<DriverLicenseDtoType, DriverLicenseDtoHelpType>;

export const DriverLicenseForm: DriverLicenseFormType = {
  name: {
    type: QuestionType.TEXT,
    label: '*Document name',
    required: true,
    value: '',
    tooltip: 'Enter a document name for display within the system',
  },
  names: {
    type: QuestionType.TEXT,
    label: '*Name(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  surnames: {
    type: QuestionType.TEXT,
    label: '*Surname(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  number: {
    type: QuestionType.TEXT,
    label: 'Document number',
  },
  dateOfIssue: {
    type: QuestionType.DATE_TIME,
    label: 'Start date',
  },
  endDate: {
    type: QuestionType.DATE_TIME,
    label: 'End date',
  },
  other: {
    type: QuestionType.TEXT,
    label: 'Other data',
  },
  category: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Category',
    items: CategoryList,
  },
  otherCategory: {
    type: QuestionType.TEXT,
    label: 'Other category',
  },
  attachments: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Document scan',
  },
  storageLocation: {
    type: QuestionType.TEXT,
    label: 'Storage location',
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
};
