import { ChangeEvent, ComponentProps, FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { AccountStatus, useGetProfileLazyQuery } from '@/shared/generated/graphql';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { BeneficiaryDto } from '@/shared/lib/sj-orm/models/beneficiary.dto';

import { DrawerPickArrayOfDto } from '../drawer-pick-array-of-dto';

import { NextOwnersDrawer } from './_components/nextOwnersDrawer';
import { NextOwnerUser } from './_components/type';

export const DrawerPickNextOwner: FC<ComponentProps<typeof DrawerPickArrayOfDto>> = ({
  collection,
  control,
  field,
  filter,
  isOpen,
  memoValue,
  name,
  setIsOpen,
  setValue,
  type,
}) => {
  const collectionName = Array.isArray(collection) ? collection[0] : collection;
  const router = useRouter();
  const sjDb = useSJDatabase();
  const [getProfile] = useGetProfileLazyQuery();
  const [searchValue, setSearchValue] = useState('');
  const [userList, setUserList] = useState<Array<NextOwnerUser>>([]);
  const [isNeedRefetch, setIsNeedRefetch] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<Array<string>>([]);

  const refetchNestOwners = () => {
    setIsNeedRefetch((flag) => !flag);
  };

  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);

  const dtos =
    sjDb.collections[collectionName]
      ?.findMany(
        (item: Pick<NextOwnerUser, 'name' | 'nickName' | 'email' | 'isTest'>) =>
          Boolean(item.name || item.nickName || item.email) &&
          (filter?.(item as BeneficiaryDto) ?? true) &&
          !item.isTest,
      )
      .map(
        (
          item: Pick<
            NextOwnerUser,
            'createdAt' | 'nickName' | 'email' | 'group' | 'name' | 'id' | 'centralDbProfileId'
          >,
        ) => {
          return {
            createdAt: item.createdAt,
            email: item.email,
            group: item.group,
            label: item.name || item.nickName || item.email,
            value: item.id,
            centralDbProfileId: item.centralDbProfileId,
          };
        },
      ) || [];

  const handleSelect = (value?: string) => {
    setSelectedItem(value);
    memoValue.current[name] = value;

    if (field) {
      field?.onChange(value);
    }
  };

  // Get Next Owner data base info
  useEffect(() => {
    const fetchUserData = async () => {
      const updatedUsers = await Promise.all(
        dtos.map(async (user) => {
          if (user.centralDbProfileId) {
            const userDetails = (
              await getProfile({
                variables: { id: user.centralDbProfileId },
              })
            ).data?.profile;

            return {
              ...userDetails,
              ...user,
              id: String(userDetails?.id),
              code: userDetails?.beneficiaryInviteCodeAsUsedForProfile?.[0]?.code,
            };
          }

          return user;
        }),
      );

      setUserList(updatedUsers);
    };

    fetchUserData();
  }, [dtos.length, isNeedRefetch]);

  useEffect(() => {
    if (memoValue.current[name] && !control._formValues[name]) {
      setValue(name, memoValue.current[name]);
    }
  }, [memoValue.current, name]);

  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const lowerCase = e.target.value.toLowerCase().trim();
    setSearchValue(lowerCase);
  };

  const filteredUserList =
    searchValue === ''
      ? userList
      : userList
          .filter((el) => {
            const userLabel = el.label;
            const userEmail = el.email;

            if (!userLabel && !userEmail) {
              return false;
            }

            return !!(
              userLabel?.toLowerCase().includes(searchValue) ||
              userEmail?.toLowerCase().includes(searchValue)
            );
          })
          .sort((a, b) => {
            if (a.label && b.label) {
              return a.label.localeCompare(b.label);
            } else if (a.email && b.email) {
              return a.email.localeCompare(b.email);
            }

            return 0;
          });

  const onAddNewClick = async () => {
    if (!type && collection !== CollectionName.BENEFICIARIES) {
      return;
    }

    localStorage.removeItem(`new-family-member-added`);

    await router.replace({
      pathname: window.location.pathname,
      query: {
        fastAdd: `${collectionName}:${type}`,
        fastTrack: true,
      },
    });
  };

  const onSelectClick = () => {
    setIsOpen(false);
    localStorage.removeItem(`new-family-member-added`);
  };

  const registeredUser = filteredUserList
    .filter((user) => user.status === AccountStatus.Active)
    .sort((a, b) => a.label!.localeCompare(b.label!));

  const notRegisteredUser = filteredUserList
    .filter((user) => user.status === AccountStatus.Inactive && user.invitationLastSentTime)
    .sort((a, b) => a.label!.localeCompare(b.label!));

  const notInvitedUser = filteredUserList
    .filter((user) => user.status === AccountStatus.Inactive && !user.invitationLastSentTime)
    .sort((a, b) => a.label!.localeCompare(b.label!));

  return (
    <NextOwnersDrawer
      filteredUser={filteredUserList}
      handleSelect={handleSelect}
      invitedUsers={invitedUsers}
      isModalOpen={isOpen}
      label={field?.value ? 'Assign a Family Member' : 'Select Family Member'}
      notInvitedUser={notInvitedUser}
      notRegisteredUser={notRegisteredUser}
      onAddNewClick={onAddNewClick}
      onSelectClick={onSelectClick}
      refetchNestOwners={refetchNestOwners}
      registeredUser={registeredUser}
      searchInputHandler={inputHandler}
      searchValue={searchValue}
      selectedItem={selectedItem}
      setInvitedUsers={setInvitedUsers}
      setIsModalOpen={setIsOpen}
    />
  );
};
