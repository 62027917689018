import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { Badge, Button, Typography } from 'antd';
import styled from 'styled-components';

import { Icon } from '@/shared/components';
import { IButtonAmount } from '@/shared/components/button-amount/types';
import { Row } from '@/shared/components/layout';
import { COLORS } from '@/shared/constants/colors';

const ButtonAmount = ({
  icon,
  title,
  amount,
  link,
  onClick,
  style,
  dataCy,
}: IButtonAmount): JSX.Element => {
  const { push } = useRouter();

  const onClickHandler = useCallback(
    async (url: string) => {
      onClick?.();
      await push(url, undefined, { shallow: true });
    },
    [onClick, link],
  );

  return (
    <StyledButton
      block
      size="large"
      onClick={() => onClickHandler(link)}
      style={style}
      data-testid={dataCy}
    >
      <Row
        nowrap
        alignItems={'center'}
        justifyContent={'space-between'}
        paddingLeft={12}
        style={{ flex: 1 }}
      >
        <Row direction={'row'} alignItems={'center'} gap={10}>
          {icon && <Icon icon={icon} color={COLORS.colorPrimary} fontSize={19} />}
          <Text strong>{title}</Text>
        </Row>

        <Badge
          status="default"
          count={amount}
          showZero
          color={COLORS.colorFillContent}
          style={{ color: COLORS.black }}
        />
      </Row>
    </StyledButton>
  );
};

export { ButtonAmount };

const StyledButton = styled(Button)`
  &&& {
    border-radius: 12px;
  }
`;

const Text = styled(Typography.Text)`
  &&& {
    font-size: 16px;
  }
`;
