import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, ChartOptions, LinearScale } from 'chart.js';

import { COLORS } from '@/shared/constants/colors';

ChartJS.register(CategoryScale, LinearScale, BarElement);

export const BarStackedChart = ({
  items,
  labels: externalLabels,
}: {
  items: Array<number>;
  labels: Array<string>;
}) => {
  const options: ChartOptions<'bar'> = {
    indexAxis: 'y' as const,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        display: false,
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        display: false,
      },
    },

    plugins: {
      tooltip: {
        callbacks: {
          label: ({ datasetIndex }) => {
            return `${externalLabels[datasetIndex]}: ${items[datasetIndex]}%`;
          },
        },
      },
    },
  };

  const labels = ['Assets'];

  const data = {
    labels,
    datasets: items?.map((item, index) => ({
      label: externalLabels[index] || '',
      data: labels.map(() => item),
      backgroundColor: COLORS.chartColors[index],
    })),
  };

  return <Bar options={options} data={data} height={50} />;
};
