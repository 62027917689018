import { useRouter } from 'next/router';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

import { TBackButtonProps } from '@/shared/components/back-button/types';
import { Row } from '@/shared/components/layout';
import { COLORS } from '@/shared/constants/colors';

export function BackButton({
  overrideOnclick,
  iconColor,
  children,
  buttonCss,
  style,
}: TBackButtonProps): JSX.Element {
  const router = useRouter();

  return (
    <StyledButton
      ghost
      style={{ ...style, height: 40 }}
      onClick={() => (overrideOnclick ? overrideOnclick() : router.back())}
      type="link"
      data-testid={'backBtn'}
      buttonCss={buttonCss}
    >
      <Row nowrap alignCenter gapColumn={8}>
        <LeftOutlined color={iconColor ? iconColor : COLORS.colorLink} style={{ fontSize: 18 }} />

        {children}
      </Row>
    </StyledButton>
  );
}

const StyledButton = styled(Button)<{
  buttonCss: TBackButtonProps['buttonCss'];
}>`
  &&& {
    ${({ buttonCss }) => buttonCss ?? ''}
  }
`;
