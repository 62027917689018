import { useCallback, useMemo, useState } from 'react';

import { isExported } from '@/shared/constants/constants';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSecureJsonCollectionsStore } from '@/shared/lib/stores/secure-json-collections.store';
import { Synchronizer } from '@/shared/lib/synchronizer/core';
import {
  ISyncQueueJob,
  SyncQueueJobMethod,
  useSyncQueueStore,
} from '@/shared/lib/synchronizer/sync-queue.store';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { useProfileStore } from '@/shared/store/profile.store';
import { log } from '@/shared/utils/log';
import { sleep } from '@/shared/utils/misc';

import { SecureJsonBase } from '../../secure-json/core/secure-json-base';

import { useFileLoader } from './use-file-loader';
import { useGqlLoader } from './use-gql-loader';

export interface ISynchronizer {
  isSyncing: boolean;
  sync: () => Promise<boolean>;
}

const sortDateDesc = (a: { createdAt: Date }, b: { createdAt: Date }) => {
  return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
};
const isJobSinglePush = (job: ISyncQueueJob) => job.method === SyncQueueJobMethod.SINGLE_PULL;
const isJobPush = (job: ISyncQueueJob) => job.method === SyncQueueJobMethod.PUSH;
const isJobNotPush = (job: ISyncQueueJob) => job.method !== SyncQueueJobMethod.PUSH;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useSynchronizer = (): ISynchronizer => {
  const profileStore = useProfileStore();
  const { keyPair, ...keyPairStore } = useKeyPairStore();
  const sjcStore = useSecureJsonCollectionsStore();
  const syncQueueStore = useSyncQueueStore();

  const graphQLLoader = useGqlLoader();
  const fileLoader = useFileLoader();

  const [isSyncing, setIsSyncing] = useState(false);

  const loader = isExported ? fileLoader : graphQLLoader;
  const encryptionType = isExported ? keyPairStore.encryptionType : profileStore.encryptionType;

  const sync = useCallback(async (): Promise<boolean> => {
    if (!encryptionType) {
      log.error('Synchronizer: sync called');
      return false;
    }

    log.trace('Synchronizer: sync called');

    if (!keyPair || syncQueueStore.jobs.length === 0) {
      log.trace(
        '[useSynchronizer] sync skip',
        'keyPair',
        keyPair !== undefined,
        'jobs: ',
        syncQueueStore.jobs.length,
      );
      return false;
    }

    if (isSyncing) {
      log.trace('[useSynchronizer] sync progress jobs:', syncQueueStore.jobs.length);
      return false;
    } else {
      log.trace('[useSynchronizer] sync start jobs:', syncQueueStore.jobs.length);
    }

    setIsSyncing(true);

    const onCollectionLoaded = (name: CollectionName, collection: SecureJsonBase) => {
      sjcStore.setCollection(name, collection, true);
    };
    const synchronizer = new Synchronizer(encryptionType, keyPair, loader, onCollectionLoaded);
    let jobs = syncQueueStore.jobs.sort(sortDateDesc);

    // push first with save order
    jobs = [...jobs.filter(isJobPush), ...jobs.filter(isJobNotPush)];
    const onlySinglePull = jobs.every(isJobSinglePush);

    try {
      if (onlySinglePull) {
        // In singlePull only one item in job.collections
        // TODO split by chunk 3 or 4 requests for prevent bloking browser limit
        await Promise.allSettled(
          jobs.map((iJob) => {
            return synchronizer
              .pull(iJob.collections[0].collectionName)
              .then(() => syncQueueStore.removeJob(iJob.id));
          }),
        );
        setIsSyncing(false);
        return true;
      }

      for await (const job of jobs) {
        if (job.method === SyncQueueJobMethod.PULL) {
          const collectionNameArray = Object.values(CollectionName);
          for (const collectionName of collectionNameArray) {
            await synchronizer.pull(collectionName);
          }

          syncQueueStore.removeJob(job.id);
          continue;
        }
        if (job.method === SyncQueueJobMethod.SINGLE_PULL) {
          for await (const collection of job.collections) {
            await synchronizer.pull(collection.collectionName);
          }

          syncQueueStore.removeJob(job.id);
          continue;
        }

        if (job.method === SyncQueueJobMethod.PUSH) {
          for await (const collection of job.collections) {
            await synchronizer.push(collection.collectionName, collection.collection);

            // delay to avoid mix up order of jobs
            const DELAY_MS = 150;
            await sleep(DELAY_MS);

            syncQueueStore.removeJob(job.id);
          }
        }
      }
    } catch (e) {
      setIsSyncing(false);
      log.error('[useSynchronizer] processing jobs', e);
      return false;
    }

    setIsSyncing(false);

    return true;
  }, [isSyncing, loader, encryptionType, keyPair, sjcStore, syncQueueStore.jobs]);

  return useMemo(
    () => ({
      isSyncing,
      sync,
    }),
    [isSyncing, sync],
  );
};
