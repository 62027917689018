import { create } from 'zustand';

import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';

export interface ISecureJsonCollectionsStore {
  syncStatus: Map<CollectionName, boolean>;
  collections: Map<CollectionName, SecureJsonBase>;
  setSyncStatus: (name: CollectionName, status: boolean) => void;
  setCollection: <T>(
    name: CollectionName,
    secureJsonBase: SecureJsonBase<T>,
    isInit?: boolean,
  ) => void;
  clear: () => void;
}

export const useSecureJsonCollectionsStore = create<ISecureJsonCollectionsStore>((set) => ({
  syncStatus: new Map(),
  collections: new Map(),
  setSyncStatus: (name, status): void => {
    set((state) => {
      const syncStatus = new Map(state.syncStatus);
      syncStatus.set(name, status);

      return { syncStatus };
    });
  },
  setCollection: (name, secureJsonBase, isInit): void => {
    set((state) => {
      const collections = new Map(state.collections);
      collections.set(name, secureJsonBase);

      if (isInit) {
        return { collections };
      } else {
        const syncStatus = new Map(state.syncStatus);
        syncStatus.set(name, false);

        return { collections, syncStatus };
      }
    });
  },
  clear: (): void =>
    set({
      collections: new Map(),
      syncStatus: new Map(),
    }),
}));
