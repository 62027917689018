import { useCallback, useMemo, useState } from 'react';

import { PublicKey } from '@/shared/lib/secure-json/core/crypto-core/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';

import { OmitedBaseDto } from '../forms/form-wrapper.types';

type UseFormSaveInputType = {
  collectionName: CollectionName;
  initialDtoData?: Record<string, unknown>;
  additionalPublicKeys?: PublicKey[];
  updateOrSave?: 'save' | 'update';
};

export const useFormSave = ({
  collectionName,
  additionalPublicKeys = [],
  initialDtoData = {},
  updateOrSave = 'save',
}: UseFormSaveInputType) => {
  const [formCache, setFormCache] = useState<Omit<BaseDto, 'id'> | Record<string, unknown>>(
    initialDtoData,
  );
  const sjDb = useSJDatabase();
  const { keyPair } = useKeyPairStore();

  const onSubmit = useCallback((data: BaseDto | OmitedBaseDto) => {
    setFormCache((prevState) => ({ ...prevState, ...data }));
  }, []);

  const finish = useCallback(
    async (overrideData?: BaseDto | OmitedBaseDto) => {
      const collection = sjDb.collections[collectionName];
      if (!collection) {
        throw new Error(`[use-form-save] mepty colection: ${collectionName}`);
      }
      const operation = updateOrSave === 'update' ? 'update' : 'create';

      const id =
        updateOrSave === 'save' ? sjDb.generateId(collectionName) : (overrideData as BaseDto)?.id;

      const data = {
        id,
        ...formCache,
        ...(overrideData ? overrideData : {}),
      } as never;

      if (operation === 'update') {
        return await collection.update(data);
      } else {
        const keys = [keyPair?.publicKey || ''].concat(additionalPublicKeys);
        return await collection.create(data, keys);
      }
    },
    [formCache, sjDb],
  );

  return useMemo(() => ({ onSubmit, finish, formCache }), [onSubmit, finish, formCache, sjDb]);
};

export const TODO = 2;
