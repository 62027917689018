import { PublicKey, SymmetricKey } from '@/shared/lib/secure-json/core/crypto-core/types';
import { EncryptedData } from '@/shared/lib/secure-json/core/secure-json-encryptor/types';

export type Key = string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DecryptedData = any;

export class RecordNotFoundError extends Error {
  constructor() {
    super('Record not found');
  }
}

export type EncryptedSymmetricKey = string;

export interface Value {
  decryptedData?: DecryptedData;
  encryptedData?: EncryptedData | undefined;
  decryptedSymmetricKey?: SymmetricKey;
  encryptedSymmetricKeys: Map<PublicKey, EncryptedSymmetricKey>;
  permissions: Map<PublicKey, Set<Permission>>;
}

export enum Permission {
  READ = 'READ',
  WRITE = 'WRITE',
}

export interface ISetParameters {
  key: Key;
  data: DecryptedData;
  publicKeys?: Array<PublicKey>;
  permissions?: Map<PublicKey, Set<Permission>>;
}

export interface CollectionSymmetricKey {
  decryptedValue?: SymmetricKey;
  encryptedValue?: string;
}

export type CollectionSymmetricKeys = Map<PublicKey, CollectionSymmetricKey>;
