import type { ChangeEvent, FC, KeyboardEvent, RefObject } from 'react';
import { ClipboardEvent, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'antd';
import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';

type InputCodeProps = {
  clear?: boolean;
  firstInputPostfix?: string;
  triggerRepeat?: boolean;
  uniqPostfix?: string;
  autofocus?: boolean;
  showCode?: boolean;
  onInputPinCode: (value: string) => void;
};

type TargetValueType = {
  value: string;
  id: string;
};

type FieldNumberProps = {
  className?: string;
  hidden: boolean;
  index: number;
  name: string;
  testId: string;
  ref: RefObject<HTMLInputElement>;
};

type InputCodeForm = {
  field1: number | null;
  field2: number | null;
  field3: number | null;
  field4: number | null;
  field5: number | null;
  field6: number | null;
};

const NUMBERS_REGEX = /^\d+$/;

const initialValues = {
  field1: '',
  field2: '',
  field3: '',
  field4: '',
  field5: '',
  field6: '',
};

export const InputCode: FC<InputCodeProps> = ({
  clear,
  firstInputPostfix,
  triggerRepeat,
  uniqPostfix = '',
  autofocus,
  showCode,
  onInputPinCode,
}) => {
  const [value, setValue] = useState('');

  const [form] = Form.useForm<InputCodeForm>();

  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const inputRef4 = useRef<HTMLInputElement>(null);
  const inputRef5 = useRef<HTMLInputElement>(null);
  const inputRef6 = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line complexity
  const onlyNumberInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      !NUMBERS_REGEX.test(event.key) &&
      event.code !== 'ArrowLeft' &&
      event.code !== 'ArrowRight' &&
      event.code !== 'Backspace' &&
      event.code !== 'Delete' &&
      event.code !== 'Tab' &&
      event.code !== 'ControlLeft' &&
      event.code !== 'ControlRight' &&
      event.code !== 'KeyV' &&
      event.key !== 'Backspace' &&
      event.key !== 'Enter'
    ) {
      event.preventDefault();
      const target = event.target as HTMLInputElement;
      target.value = target.value.replace(/[^\d]/g, '');
    } else if (
      !(event.target as unknown as TargetValueType).value &&
      (event.code === 'ArrowLeft' ||
        event.code === 'Backspace' ||
        event.code === 'Delete' ||
        event.key === 'Backspace' ||
        event.key === 'Delete')
    ) {
      const id = (event.target as unknown as TargetValueType).id.slice(-1);
      let fieldId = `field${uniqPostfix}${Number(id) - 1}`;

      if (id === '1') {
        if (!firstInputPostfix) return;
        fieldId = `field${firstInputPostfix}${6}`;
      }

      const el = document.getElementById(fieldId) as HTMLInputElement | null;

      if (!el) return;

      const end = el.value.length;

      el.setSelectionRange(end, end);
      el.focus();
    }
  };

  const onChange = () => {
    const result = [inputRef1, inputRef2, inputRef3, inputRef4, inputRef5, inputRef6].reduce(
      (acc, cur) => {
        return cur.current?.value ? acc + cur.current?.value : acc;
      },
      '',
    );

    setValue(result);
  };

  const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const pasted = event.clipboardData.getData('text/plain');
    const wordsArray = pasted.trim().replace(/\D/g, '').split('').slice(0, 6);

    if (!wordsArray) return;
    if (wordsArray.length > 0 && inputRef1.current) inputRef1.current.value = wordsArray[0];
    if (wordsArray.length > 1 && inputRef2.current) inputRef2.current.value = wordsArray[1];
    if (wordsArray.length > 2 && inputRef3.current) inputRef3.current.value = wordsArray[2];
    if (wordsArray.length > 3 && inputRef4.current) inputRef4.current.value = wordsArray[3];
    if (wordsArray.length > 4 && inputRef5.current) inputRef5.current.value = wordsArray[4];
    if (wordsArray.length > 5 && inputRef6.current) inputRef6.current.value = wordsArray[5];

    const data = wordsArray.join('');

    setValue(data);
  };

  const changeFocus = (index: number) => {
    const list = [inputRef1, inputRef2, inputRef3, inputRef4, inputRef5, inputRef6];

    list[index].current?.focus();
  };

  const onFieldChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    if (index < 5) {
      if (e.target.value) {
        changeFocus(index + 1);
      }
    } else if (triggerRepeat && e.target.value) {
      const nextInputCode = document.querySelector('.repeatInput1') as HTMLInputElement;
      nextInputCode?.focus();
    }
  };

  const FieldNumber = useMemo(() => {
    const Component = forwardRef<HTMLInputElement, FieldNumberProps>(
      ({ className, index, name, testId, hidden }, forRef) => (
        <Form.Item name={name}>
          <Input
            className={className}
            style={{
              width: 54,
              height: 54,
              fontSize: 24,
              fontWeight: 700,
              margin: 0,
            }}
            data-testid={testId}
            ref={forRef}
            maxLength={1}
            type={hidden ? 'password' : 'text'}
            pattern={'[0-9]'}
            onKeyDown={onlyNumberInput}
            onInput={onlyNumberInput}
            onPaste={onPaste}
            onChange={(e) => onFieldChange(e, index)}
            autoComplete="off"
          />
        </Form.Item>
      ),
    );
    Component.displayName = 'FieldNumber';

    return Component;
  }, []);

  useEffect(() => {
    if (clear) {
      form.resetFields();
      setValue('');
      inputRef1.current?.focus();
    }
  }, [clear]);

  useEffect(() => {
    onInputPinCode(value);
  }, [value]);

  useEffect(() => {
    if (autofocus) {
      changeFocus(0);
    }
  }, []);

  return (
    <Form
      form={form}
      style={{
        maxWidth: 600,
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
      }}
      initialValues={initialValues}
      onChange={onChange}
    >
      <FieldNumber
        className={firstInputPostfix ? 'repeatInput1' : undefined}
        hidden={!showCode && value.length > 1}
        index={0}
        name={`field${uniqPostfix}1`}
        ref={inputRef1}
        testId={'pinCodeInput1'}
      />
      <FieldNumber
        hidden={!showCode && value.length > 2}
        index={1}
        name={`field${uniqPostfix}2`}
        ref={inputRef2}
        testId={'pinCodeInput2'}
      />
      <FieldNumber
        hidden={!showCode && value.length > 3}
        index={2}
        name={`field${uniqPostfix}3`}
        ref={inputRef3}
        testId={'pinCodeInput3'}
      />
      <FieldNumber
        hidden={!showCode && value.length > 4}
        index={3}
        name={`field${uniqPostfix}4`}
        ref={inputRef4}
        testId={'pinCodeInput4'}
      />

      <FieldNumber
        hidden={!showCode && value.length > 5}
        index={4}
        name={`field${uniqPostfix}5`}
        ref={inputRef5}
        testId={'pinCodeInput5'}
      />
      <FieldNumber
        hidden={!showCode && value.length > 6}
        index={5}
        name={`field${uniqPostfix}6`}
        ref={inputRef6}
        testId={'pinCodeInput6'}
      />
    </Form>
  );
};

const Input = styled.input`
  height: 42px;
  width: 32px;
  margin-left: 4px;
  display: inline-flex;
  padding: 4px;
  text-align: center;
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  border: 1px solid ${COLORS.colorPrimaryBorder};
  border-radius: 6px;
  outline: 0;
  transition: all 0.2s;

  &:hover {
    border: 1px solid ${COLORS.colorPrimaryBorderHover};
  }

  &:focus {
    box-shadow: 0px 0px 2px 1px ${COLORS.colorPrimaryBgHover};
    border: 1px solid ${COLORS.colorPrimaryBorderHover};
  }
`;
