import { TMigration } from '@/shared/lib/migrator/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';

const up = (store: Map<CollectionName, SecureJsonBase>) => {
  const assistantMnemonicCollection = store.get(CollectionName.ASSISTANT_MNEMONIC);

  if (assistantMnemonicCollection) {
    for (const [key, item] of assistantMnemonicCollection?.items || []) {
      delete (item.decryptedData as { name?: string }).name;
      assistantMnemonicCollection.set({
        key,
        data: item.decryptedData,
      });
    }
  }
};

export const migration003: TMigration = {
  version: '0.0.3',
  up,
};
