import { create } from 'zustand';

type SynchronizerStore = {
  isInitialized: boolean;
  isLoading: boolean;
  collectionPulled: boolean;
  setIsInitialized: (is: boolean) => void;
  setIsLoading: (is: boolean) => void;
  setCollectionPulled: (value: boolean) => void;
  clear: VoidFunction;
};

export const useSynchronizerStore = create<SynchronizerStore>((set) => ({
  isLoading: true,
  isInitialized: false,
  collectionPulled: false,
  setIsLoading: (isLoading) => set({ isLoading }),
  setIsInitialized: (isInitialized) => set({ isInitialized }),
  setCollectionPulled: (collectionPulled) => set({ collectionPulled }),

  clear: () => set({ isLoading: true, isInitialized: false, collectionPulled: false }),
}));
