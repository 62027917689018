// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable security/detect-object-injection */
export type ComparisonType = 'EQUAL' | 'NOT_EQUAL';
export interface Comparison<T> {
  type: ComparisonType;
  compareData: T | RelatingExpression<T>;
}

export type ValueType<T> = Comparison<T> | T | RelatingExpression<T>;

export type RelatingExpression<T> = {
  [P in keyof T]?: ValueType<T[P]>;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export function checkCondition<T>(data: T | null, condition: RelatingExpression<T>): boolean {
  if (!data) return false;

  // eslint-disable-next-line guard-for-in
  for (const key in condition) {
    const dataValue = data[key];
    const conditionValue = condition[key];

    if (typeof conditionValue === 'object' && conditionValue !== null) {
      if ('type' in conditionValue) {
        const typedCondition = conditionValue as Comparison<unknown>;
        const compareData =
          typeof typedCondition.compareData === 'object'
            ? checkCondition(dataValue, typedCondition.compareData as RelatingExpression<unknown>)
            : typedCondition.compareData;

        switch (typedCondition.type) {
          case 'EQUAL':
            if (dataValue !== compareData) {
              return false;
            }
            break;
          case 'NOT_EQUAL':
            if (dataValue === compareData) {
              return false;
            }
            break;
          default:
            throw new Error(`Unknown condition type: ${typedCondition.type}`);
        }
      } else if (!checkCondition(dataValue, conditionValue as RelatingExpression<unknown>)) {
        return false;
      }
    } else if (dataValue !== conditionValue) {
      return false;
    }
  }

  return true;
}

type FirstItem = Record<string, unknown>;
type SecondItem = Record<string, undefined | { value?: unknown }>;
export const checkCondition2 = (a: FirstItem, b: SecondItem) => {
  const key = Object.keys(b)[0];

  if (Array.isArray(a?.[key])) {
    return (a[key] as Array<unknown>).includes(b[key]?.value);
  }

  if (a[key] || typeof a[key] === 'boolean') {
    return a[key] === b[key]?.value;
  } else {
    return false;
  }
};
