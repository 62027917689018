import { useCallback, useMemo } from 'react';

import { useNotifications } from '@/entities/notification';
import {
  AccountStatus,
  GetBeneficiariesQuery,
  ScenarioMode,
  ScenarioQuery,
  useActivateScenarioMutation,
  useCancelScenarioMutation,
  useCreateScenarioMutation,
  useGetProfileLazyQuery,
  useRemoveScenarioMutation,
} from '@/shared/generated/graphql';
import { EncryptionType } from '@/shared/lib/secure-json/core/crypto-core/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';
import { NotificationType } from '@/shared/lib/sj-orm/models/notification.dto';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { log } from '@/shared/utils/log';
import { sleep } from '@/shared/utils/misc';

type TestScenarioType = {
  scenario: ScenarioQuery['scenario'];
  assetData: AssetDto;
  beneficiaryCentralDB: GetBeneficiariesQuery['getBeneficiaries'][number];
};

type ActivationScenarioType = {
  scenarioId: string;
  mode: ScenarioMode;
};

export const useScenarios = () => {
  const [cancelScenarioMutation] = useCancelScenarioMutation();
  const [createScenario] = useCreateScenarioMutation();
  const [activateScenario] = useActivateScenarioMutation();
  const [deleteScenario] = useRemoveScenarioMutation();
  const [getProfile] = useGetProfileLazyQuery();
  const { createNotification } = useNotifications();
  const sjDb = useSJDatabase();
  const { keyPair } = useKeyPairStore();
  const store = useSJDatabase();

  const cancelScenario = async (id: string) => {
    return await cancelScenarioMutation({
      variables: { scenarioId: id },
    });
  };

  const isPossibleTestScenario = async (beneficiaryDBId: number) => {
    const profileInfo = (
      await getProfile({
        variables: { id: beneficiaryDBId },
      })
    ).data?.profile;

    // eslint-disable-next-line sonarjs/prefer-single-boolean-return -- unreadable variant
    if (!profileInfo || profileInfo?.status === AccountStatus.Inactive) {
      return false;
    }

    return true;
  };

  const activationScenario = async (params: ActivationScenarioType) => {
    const activatedScenario = await activateScenario({
      variables: { scenarioId: params.scenarioId },
    });

    if (!activatedScenario.data?.activateScenario) return false;

    if (activatedScenario.data?.activateScenario) {
      log.debug('Scenario is activated');

      if (params.mode === ScenarioMode.Normal) {
        createNotification({
          type: NotificationType.SCENARIO_ACTIVATED,
          scenarioId: params.scenarioId,
        });
      }
    }

    return true;
  };

  const removeScenario = async (scenarioId: string) => {
    log.debug(`Deleting scenario...`);

    const removeResult = await deleteScenario({
      variables: { scenarioId },
    });

    return Boolean(removeResult.data?.removeScenario);
  };

  const testScenario = async (params: TestScenarioType): Promise<boolean> => {
    const publicKey = keyPair?.publicKey;

    if (!params.scenario) {
      log.warn('[handleRunTestScenario] params.scenario is empty');
      return false;
    }

    if (!publicKey) {
      log.warn('[handleRunTestScenario] empty publicKey');
      return false;
    }

    if (!params.scenario.beneficiaryId || !params.scenario.dtoIds || !params.scenario.triggerType) {
      log.warn('[handleRunTestScenario] empty beneficiaryId | dtoIds | triggerType');
      return false;
    }

    const newAsset: AssetDto = {
      id: sjDb.generateId(CollectionName.ASSETS),
      name: `[TEST] - ${
        params.assetData.nickName ?? params.assetData.nickname ?? params.assetData.name
      }`,

      category: params.assetData.category,
      country: params.assetData.country,
      purchaseValue: params.assetData.purchaseValue,
      isTest: true,
    };

    const asset = await sjDb.collections.assets?.create(newAsset, [publicKey]);

    if (!asset) {
      log.warn('[handleRunTestScenario] empty asset for test scenario');
      return false;
    }

    const { data } = await createScenario({
      variables: {
        beneficiaryProfileId: params.scenario.beneficiaryId,
        triggerValue: Number(params.scenario.triggerValue),
        dtoIds: [asset.id],
        triggerType: params.scenario.triggerType,
        mode: ScenarioMode.Test,
        notificationValue: Number(params.scenario.notificationValue),
        notificationPeriod: Number(params.scenario.notificationPeriod),
        notificationTime: params.scenario.notificationTime,
      },
    });

    const centralDbId = data?.createScenario.id;
    const scenarioName = getScenarioName(params.scenario.id as string)?.name;

    if (centralDbId && scenarioName) {
      sjDb.collections.scenarioMetaInfos?.create(
        {
          id: sjDb.generateId(CollectionName.SCENARIO_META_INFO),
          centralDbId,
          name: scenarioName,
        },
        [publicKey],
      );
    }

    if (params.beneficiaryCentralDB?.publicKey && data?.createScenario.id) {
      if (asset && params.beneficiaryCentralDB?.publicKey) {
        const encryptionType = params.beneficiaryCentralDB.encryptionType?.title as EncryptionType;

        if (!encryptionType) {
          throw new Error('Wrong encryption type');
        }

        await sjDb.collections.assets?.putPublicKey(
          asset.id,
          params.beneficiaryCentralDB.publicKey,
          encryptionType,
        );
      }

      await activationScenario({
        scenarioId: data.createScenario.id,
        mode: ScenarioMode.Test,
      });

      await sleep();
      return true;
    } else {
      return false;
    }
  };

  const getScenarioName = useCallback(
    (scenarioId: string) => {
      return store.collections.scenarioMetaInfos?.findOne(
        (scenario) => scenario.centralDbId === scenarioId,
      );
    },
    [store],
  );

  return useMemo(
    () => ({
      getScenarioName,
      activationScenario,
      cancelScenario,
      isPossibleTestScenario,
      removeScenario,
      testScenario,
    }),
    [getScenarioName, cancelScenario, isPossibleTestScenario, testScenario],
  );
};
