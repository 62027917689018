import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum TaxIDType {
  resident = 'resident',
  nonResident = 'nonResident',
}

export const TaxIDList = [
  {
    label: 'Resident',
    key: TaxIDType.resident,
  },
  {
    label: 'Non-resident',
    key: TaxIDType.nonResident,
  },
];

export type TaxIDDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  name: string;
  names: string;
  surnames: string;
  country: string;
  number?: string;
  docType?: TaxIDType;
  storageLocation?: string;
  comments?: string;
};

export type TaxIDDtoHelpType = {
  name: QuestionType.TEXT;
  names: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  number?: QuestionType.TEXT;
  docType?: QuestionType.SELECT_FROM_LIST;
  attachments?: QuestionType.FILE_UPLOAD;
  storageLocation?: QuestionType.TEXT;
  comments?: QuestionType.TEXT_MULTILINE;
};

type TaxIDFormType = TDtoWrapper<TaxIDDtoType, TaxIDDtoHelpType>;

export const TaxIDForm: TaxIDFormType = {
  name: {
    type: QuestionType.TEXT,
    label: '*Document name',
    required: true,
    value: '',
    tooltip: 'Enter a document name for display within the system',
  },
  names: {
    type: QuestionType.TEXT,
    label: '*Name(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  surnames: {
    type: QuestionType.TEXT,
    label: '*Surname(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  number: {
    type: QuestionType.TEXT,
    label: 'Document number',
  },
  docType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Document type',
    items: TaxIDList,
  },
  attachments: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Document scan',
  },
  storageLocation: {
    type: QuestionType.TEXT,
    label: 'Storage location',
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
};
