import { FC, MutableRefObject, useEffect, useMemo } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import styled from 'styled-components';

import { useFileEncryptionUpload } from '@/entities/file/hooks/use-file-encryption-upload';
import { Tooltip } from '@/shared/components/tooltip';
import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';

import { MAX_FILES_COUNT, validateSize, validateType } from './helpers';

// TODO add typing from antd/upload when it added
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UploadRequestOptions = any;

type FileUploadingComponentProps = {
  id: string;
  label: string;
  name: string;
  control: Control;
  memoizedValue: MutableRefObject<Record<string, unknown>>;
  question: {
    tooltip?: string;
    required?: boolean;
    isMultiple?: boolean;
  };
  t: (key: string) => string;
  setValue: UseFormSetValue<Record<string, unknown>>;
};
export const FileUploadingComponent: FC<FileUploadingComponentProps> = ({
  id,
  label,
  name,
  // control,
  memoizedValue,
  question,
  t,
  setValue,
}) => {
  // const [fileList, setFileList] = useState();
  // const [controlledValue, setControlledValue] = useState<string[] | undefined>([]);
  const { encryptAndUploadFile, getFileMeta, findFileMetaByPath } = useFileEncryptionUpload();
  // const { setError, clearErrors } = useFormContext();

  const isMultiple = question.isMultiple;
  const maxCount = isMultiple ? MAX_FILES_COUNT : 1;
  const memoizedValueCurrent = memoizedValue.current[name];

  const listType = 'picture-card'; // text | picture-card
  // const showUploadList = true;
  const showUploadList = {
    showPreviewIcon: false,
    showRemoveIcon: true,
  };

  const showAddButton = isMultiple
    ? !memoizedValueCurrent ||
      (Array.isArray(memoizedValueCurrent) && memoizedValueCurrent.length < MAX_FILES_COUNT)
    : !memoizedValueCurrent;

  const defaultFileList = useMemo(() => {
    if (!memoizedValueCurrent) {
      return undefined;
    }

    if (Array.isArray(memoizedValueCurrent)) {
      return memoizedValueCurrent.map((filePath) => {
        const metaInfo = findFileMetaByPath(filePath);
        return {
          uid: filePath,
          name: metaInfo?.name || 'Document',
        };
      });
    } else {
      const metaInfo = findFileMetaByPath(memoizedValueCurrent as string);
      return [
        {
          uid: memoizedValueCurrent,
          name: metaInfo?.name || 'Document',
        },
      ];
    }
  }, [memoizedValueCurrent]);

  const handleChange = (info: UploadChangeParam) => {
    const { file } = info;

    if (file.status === 'done') {
      const filePath = file.response.filePath;

      if (isMultiple) {
        const newValue = ((memoizedValue.current[name] ?? []) as DtoId[]).slice(0);
        newValue.push(filePath);
        memoizedValue.current[name] = newValue;
        setValue(name, newValue);
      } else {
        memoizedValue.current[name] = filePath;
        setValue(name, filePath);
      }
    }
  };

  const handleRemove = (file: UploadFile) => {
    if (isMultiple) {
      const removedFilePath = file.response ? file.response.filePath : file.uid;
      const newValue = (memoizedValue.current[name] as string[])
        .slice(0)
        .filter((filePath) => filePath !== removedFilePath);

      memoizedValue.current[name] = newValue;
      setValue(name, newValue);
      // setControlledValue(undefined);
    } else {
      memoizedValue.current[name] = undefined;
      setValue(name, undefined);
      // setControlledValue(undefined);
    }

    return true;
  };

  // Hook beforeUpload ist working correctly, so we use handleUpload for validate file
  const handleUpload = async (options: UploadRequestOptions) => {
    // https://github.com/react-component/upload#customrequest
    const { file, onSuccess, onError, onProgress } = options;

    if (
      isMultiple &&
      Array.isArray(memoizedValueCurrent) &&
      memoizedValueCurrent.length >= maxCount
    ) {
      onError();
      return;
    }

    const isValidType = validateType(file.type);
    const isValidSize = validateSize(file.size);

    if (!isValidType) {
      // setError(name, { message: 'Invalid file type' });
      onError();
      return;
    }

    if (!isValidSize) {
      // setError(name, { message: 'File size is more than 50 MB' });
      onError();
      return;
    }

    // just mock
    onProgress({ percent: 20 });

    const filePath = await encryptAndUploadFile(file);
    onSuccess({ filePath });
  };

  useEffect(() => {
    const fileMeta = getFileMeta(memoizedValue.current[name] as string);

    if (fileMeta) {
      setValue(name, fileMeta.id);
    }
  }, [getFileMeta, memoizedValue.current[name]]);

  return (
    <div key={id}>
      <span>{t(label)}</span>
      {question.tooltip && <Tooltip title={t(question.tooltip)} />}
      <StyledUpload
        className="avatar-uploader"
        multiple={question.isMultiple}
        maxCount={maxCount}
        listType={listType}
        showUploadList={showUploadList}
        // action={ACTION_URL}
        defaultFileList={defaultFileList}
        customRequest={handleUpload}
        onChange={handleChange}
        onRemove={handleRemove}
      >
        {showAddButton && <UploadButton />}
      </StyledUpload>
      {/* <input
        style={{ width: 0, height: 0, border: 0, outline: 0 }}
        {...control.register(name, { required: question.required })}
        value={controlledValue}
      /> */}
    </div>
  );
};

const UploadButton = () => (
  <div>
    <PlusOutlined />
  </div>
);

const StyledUpload = styled(Upload)`
  &&& {
    .ant-upload.ant-upload-select {
      width: 48px;
      height: 48px;
      border-style: solid;
    }
  }
`;
