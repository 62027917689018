import {
  getToken,
  Messaging,
  NotificationPayload,
  onMessage,
  Unsubscribe,
} from 'firebase/messaging';

export const requestForToken = (messaging: Messaging) => {
  console.log('getToken = ', messaging);

  return getToken(messaging, {
    vapidKey:
      'BPeQFRm6UWAO80e15laWVuVwHsgMrzgbXFttUVodwgOYF7ZZP-t5063dYzI84zaHK9p1r8dF4P6ZV8bMS8VPv5o',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
      return currentToken;
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = (messaging: Messaging): Unsubscribe => {
  return onMessage(messaging, (payload) => {
    console.log('onMessage:', JSON.stringify(payload));
    //showNotification(payload.notification);
  });
};

export function showNotification(payload: NotificationPayload | undefined) {
  const title = payload?.title || 'New notification';
  const body = payload?.body || 'You have a new notification';
  const icon = payload?.icon || `assets/icons/app_icon.png`;
  const image = payload?.image;
  const notificationOptions = {
    body,
    icon,
    image,
  };
  const notif = new Notification(title, notificationOptions);

  notif.onclick = () => {
    console.log('Notification clicked');
  };
}
