import { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Spin as AntSpin } from 'antd';
import { styled } from 'styled-components';

import { shouldOpenSupport } from '@/features/zendesk/helpers';
import { useZendeskStore } from '@/features/zendesk/stores/zendesk.store';
import { isExported } from '@/shared/constants/constants';
import { useAuthStore } from '@/shared/store/auth.store';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { useSettingsStore } from '@/shared/store/settings.store';

export const publicRoutes = ['/', '', 'auth', '/test/new-form', 'sysver', 'thanks'];

export const AuthProvider = ({ children }: { children: ReactElement }) => {
  const router = useRouter();

  const { token } = useAuthStore();
  const { encryptedMnemonic } = useSettingsStore();
  const { keyPair } = useKeyPairStore();
  const setShouldOpenSupport = useZendeskStore((s) => s.setShouldOpenSupport);
  const [loading, setLoading] = useState(true);

  const firstPathChunk = router.pathname.split('/')[1];
  const isPublic = publicRoutes.includes(firstPathChunk);
  const isRoot = router.pathname === '/';
  const isEnterPin = router.pathname === '/auth/login/enter-pin-code';

  const resolveWebApp = async () => {
    // TODO why ?
    if (!isPublic && !token) {
      localStorage.clear();
      router.push('/');
      setLoading(false);
      return;
    }

    if (token && encryptedMnemonic && !keyPair) {
      const needRedirect = !isPublic || !isEnterPin;
      if (needRedirect) {
        await router.push('/auth/login/enter-pin-code');
        setLoading(false);
        return;
      }
    }

    setLoading(false);
  };

  const resolveMyHub = async () => {
    if (!keyPair) {
      await router.push('/');
    }

    setLoading(false);
  };

  const resolvePath = async () => {
    if (!isExported) {
      // Web App
      await resolveWebApp();
    } else {
      // MyHub
      await resolveMyHub();
    }
  };

  // Resolve paths
  useEffect(() => {
    resolvePath();
  }, []);

  // Zendesk
  useEffect(() => {
    if (shouldOpenSupport()) {
      setShouldOpenSupport(true);
    }
  }, []);

  // Show loader on root and not public paths
  if ((isRoot || !isPublic) && loading) {
    return (
      <Loader>
        <Spin size={'large'} />
      </Loader>
    );
  }

  return <>{children}</>;
};

// TODO it should by in main layout (min-height: 100% and width: 100%)
const Loader = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spin = styled(AntSpin)`
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
