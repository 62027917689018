import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { isExported } from '@/shared/constants/constants';
import { EncryptionType, KeyPair } from '@/shared/lib/secure-json/core/crypto-core/types';

type KeyPairStoreState =
  | {
      keyPair: KeyPair;
      encryptionType: EncryptionType;
    }
  | {
      keyPair: null;
      encryptionType: null;
    };

type KeyPairStore = KeyPairStoreState & {
  setKeyPair: (keyPair: KeyPair, encryptionType: EncryptionType) => void;
  clear: () => void;
};

export const useKeyPairStore = isExported
  ? create(
      persist<KeyPairStore>(
        (set) => ({
          keyPair: null,
          encryptionType: null,
          setKeyPair: (keyPair, encryptionType) => set({ keyPair, encryptionType }),
          clear: () => set({ keyPair: undefined }),
        }),
        {
          name: 'keypair-storage',
          storage: createJSONStorage(() => sessionStorage),
        },
      ),
    )
  : create<KeyPairStore>((set) => ({
      keyPair: null,
      encryptionType: null,
      setKeyPair: (keyPair, encryptionType) => set({ keyPair, encryptionType }),
      clear: () => set({ keyPair: undefined }),
    }));
