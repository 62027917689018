import { nanoid } from 'nanoid';

import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { separator } from '@/shared/lib/hooks/use-form-render-old/constants';
import { FormItemsType, KeyString } from '@/shared/lib/hooks/use-form-render-old/types';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';
import { copyAny } from '@/shared/utils/misc';

// eslint-disable-next-line complexity
export const toDto = <TDto extends Omit<BaseDto, 'id'>>(
  data: FormItemsType,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): TDto => {
  const result = {} as Record<string, unknown>;
  const skipKeys: string[] = ['add-new'];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (data['add-new']) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newItems = data['add-new'];

    for (const [key, value] of Object.entries(newItems)) {
      skipKeys.push(key);
      const newItemsResult = [];
      const searchKeys = Object.keys(
        Object.values(value as Record<string, unknown>)[0] as Record<string, unknown>,
      );
      const itemKeys = Object.keys(value as Record<string, unknown>);
      let searchQue = 0;
      let collectQue = 0;
      while (collectQue < searchKeys.length) {
        const resultNewItems: Record<string, unknown> = {};
        while (searchQue < itemKeys.length) {
          resultNewItems[itemKeys[searchQue]] = (
            (value as Record<string, unknown>)[itemKeys[searchQue]] as Record<string, unknown>
          )[searchKeys[collectQue]];
          searchQue++;
        }
        newItemsResult.push(resultNewItems);
        searchQue = 0;
        collectQue++;
      }

      const clearData: Record<string, unknown> = {};
      for (const itemKey of itemKeys) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (data[itemKey]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          clearData[itemKey] = data[itemKey];
        }
      }
      newItemsResult.unshift(clearData);

      Object.assign(result, { [key]: newItemsResult });
    }
  }

  for (const [key, value] of Object.entries(data)) {
    const _keyArr = key.split(separator);

    const _key = _keyArr[0];
    if (skipKeys.includes(_key)) continue;

    // eslint-disable-next-line no-prototype-builtins
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        if (typeof value[0] === 'object') {
          //todo normal resolve currency
          const __key = Object.keys(value[0])[0];
          Object.assign(result, { [_key]: value[0][__key] });
          continue;
        }

        Object.assign(result, { [_key]: value });
        continue;
      }

      if (
        'Dropdown' in value &&
        'Input' in value &&
        (value.Dropdown as { key?: string })?.key &&
        value.Input
      ) {
        Object.assign(result, {
          [_key]: `${(value.Dropdown as { key: string }).key} ${value.Input}`,
        });

        continue;
      }
      if (isKeyType(value)) {
        Object.assign(result, {
          // eslint-disable-next-line security/detect-object-injection
          [_key]: result?.[_key] ? `${result?.[_key]} ${value.key}` : value.key,
        });
        continue;
      }

      if ('file' in value) {
        Object.assign(result, { [_key]: value?.file?.thumbUrl });
        continue;
      }

      Object.assign(result, { [_key]: value });
      continue;
    }
    if (_key.startsWith('file_')) {
      Object.assign(result, { [_key.split('_')[1]]: value });
      continue;
    }
    Object.assign(result, {
      [_key]: result?.[_key] ? `${result?.[_key]} ${value}` : value,
    });
  }

  return result as TDto;
};

const isKeyType = (item: unknown): item is KeyString =>
  // eslint-disable-next-line no-prototype-builtins
  !!item?.hasOwnProperty('key');

export const fromDto = (
  dto: Omit<BaseDto, 'id'> & unknown,
  form: TDtoWrapper<Omit<BaseDto, 'id'>, Record<string, unknown>>,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const addNewItems = Object.entries(form)
    .filter(([, value]) => value.type === QuestionType.RENDER_NEW_BUTTON)
    .map(([key]) => key);

  const result: typeof dto = {};

  for (const key of Object.keys(dto)) {
    if (key === 'id') continue;

    if (!addNewItems.includes(key)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[key] = dto[key];
      continue;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const items = copyAny(dto[key]);
    if (items) {
      if (Array.isArray(items)) {
        items.shift();
      }
      const _res: Record<string, unknown> = {};
      for (const newKey of addNewItems) {
        const _resNewKeys: Record<string, unknown> = {};
        for (const t of items) {
          const uniqKey = nanoid();
          const res: Record<string, unknown> = {};
          for (const _key of Object.keys(t)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const it = copyAny(t[_key]);
            res[_key] = it;
          }
          _resNewKeys[uniqKey] = res;
        }
        _res[newKey] = _resNewKeys;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result['add-new'] = _res;
    }
  }

  return result;
};
