import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { DelegatingRequestItemDto } from '@/shared/lib/sj-orm/models/delegating-request.dto';

type AssistantStoreType = {
  requestId: string;
  setRequestId: (id: string) => void;
  filledValues: DelegatingRequestItemDto[];
  addFilledValue: (value: DelegatingRequestItemDto) => void;
  clear: () => void;
};

export const useAssistantStore = create(
  persist<AssistantStoreType>(
    (set, get) => ({
      requestId: '',
      filledValues: [],
      setRequestId: (value) => set({ requestId: value }),
      addFilledValue: (value) => set({ filledValues: get().filledValues.concat(value) }),
      clear: (): void => set({ requestId: '', filledValues: [] }),
    }),
    {
      name: 'assistant-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
