import { create } from 'zustand';

import { ProfileType } from '../generated/sdk';
import { EncryptionType } from '../lib/secure-json/core/crypto-core/types';

export type ProfileStore = {
  profileType: ProfileType | null;
  encryptionType: EncryptionType | null;
  publicKey: string | null;
  isBeneficiary: boolean | null;
  mainUserId: number | null;
  setProfileType: (type: ProfileType) => void;
  setMainUserId: (id: number) => void;
  setEncryptionType: (encryptionType: EncryptionType) => void;
  setPublicKey: (publicKey: string | null) => void;
  clear: () => void;
};

export const useProfileStore = create<ProfileStore>((set) => ({
  profileType: null,
  publicKey: null,
  encryptionType: null,
  isBeneficiary: null,
  mainUserId: null,

  setProfileType: (profileType) =>
    set({
      profileType,
      isBeneficiary: profileType === ProfileType.Beneficiary,
    }),

  setMainUserId: (id) => set({ mainUserId: id }),
  setEncryptionType: (encryptionType) => set({ encryptionType }),
  setPublicKey: (publicKey) => set({ publicKey }),
  clear: () => set({ publicKey: null }),
}));
