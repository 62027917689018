// TODO rename to smth synchronizer-provider.tsx
import { FC, memo, PropsWithChildren, useEffect } from 'react';
import { useInterval } from 'react-use';

import { useDashboardStore } from '@/features/dashboard/hooks/use-dashboard';
import { isExported } from '@/shared/constants/constants';
import { useSecureJsonCollectionsStore } from '@/shared/lib/stores/secure-json-collections.store';
import { useSynchronizer } from '@/shared/lib/synchronizer/hooks/use-synchronizer';
import { SyncQueueJobMethod, useSyncQueueStore } from '@/shared/lib/synchronizer/sync-queue.store';
import { useAuthStore } from '@/shared/store/auth.store';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { log } from '@/shared/utils/log';

import { useSynchronizerStore } from './synchronizer/synchronizer.store';
import { collectionsToPull } from './helpers';

const SYNC_INTERVAL_MS = 5000;
const ADD_JOB_INTERVAL_MS = 2000;

export const StoresInitializationProvider: FC<PropsWithChildren> = memo(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  ({ children }): JSX.Element => {
    const synchronizer = useSynchronizer();
    const { keyPair } = useKeyPairStore();
    const { token } = useAuthStore();
    const syncQueueStore = useSyncQueueStore();
    const {
      setIsLoading: storeSetIsLoading,
      setIsInitialized: storeSetIsInitialized,
      collectionPulled,
      setCollectionPulled,
    } = useSynchronizerStore();
    const sjCollectionStore = useSecureJsonCollectionsStore();
    const dashboardStore = useDashboardStore();

    useInterval(() => {
      synchronizer.sync();
    }, SYNC_INTERVAL_MS);

    // Checking updates and push colleaction
    useInterval(() => {
      const notSyncedCollectionNames = Array.from(sjCollectionStore.syncStatus.entries())
        .filter(([, status]) => !status)
        .map(([name]) => name);

      for (const notSyncedCollectionName of notSyncedCollectionNames) {
        if (!sjCollectionStore.collections.get(notSyncedCollectionName)) {
          continue;
        }

        log.trace('[StoresInitializationProvider] Add job push', notSyncedCollectionName);

        // TODO clean
        if (notSyncedCollectionName === 'assets') {
          console.log(
            '[col] push job',
            notSyncedCollectionName,
            sjCollectionStore.collections.get(notSyncedCollectionName)!,
          );
        }

        syncQueueStore.addJob({
          method: SyncQueueJobMethod.PUSH,
          collections: [
            {
              collectionName: notSyncedCollectionName,
              collection: sjCollectionStore.collections.get(notSyncedCollectionName)!.toString(),
            },
          ],
        });

        sjCollectionStore.setSyncStatus(notSyncedCollectionName, true);
      }
    }, ADD_JOB_INTERVAL_MS);

    // First store loading
    useEffect(() => {
      if (!dashboardStore._hasHydrated) {
        return;
      }

      if (!token && !isExported) {
        storeSetIsInitialized(false);
      }

      if (keyPair?.privateKey && (token || isExported)) {
        if (!collectionPulled) {
          storeSetIsLoading(true);
          log.info('Start stores initialization...');

          collectionsToPull.forEach((iName) => {
            syncQueueStore.addJob({
              method: SyncQueueJobMethod.SINGLE_PULL,
              collectionName: iName,
            });
          });

          setCollectionPulled(true);
          storeSetIsInitialized(true);
        }
      } else {
        storeSetIsLoading(false);

        log.trace('Stores already initialized', {
          keyPair,
          token,
        });
      }
    }, [
      collectionPulled,
      keyPair?.privateKey,
      token,
      dashboardStore,
      storeSetIsLoading,
      storeSetIsInitialized,
    ]);

    useEffect(() => {
      if (!synchronizer.isSyncing) {
        return undefined;
      }

      const handelBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = true;
      };

      window.addEventListener('beforeunload', handelBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handelBeforeUnload);
      };
    }, [synchronizer.isSyncing]);

    return <>{children}</>;
  },
);

StoresInitializationProvider.displayName = 'StoresInitializationProvider';

// const StoresInitializationProvider: FC<PropsWithChildren> = ({
//   children,
// }): JSX.Element => {
//   const {
//     setIsLoading: storeSetIsLoading,
//     setIsInitialized: storeSetIsInitialized,
//   } = useSynchronizerStoreStore();
//   const [isInitialized, setIsInitialized] = useState<boolean>(false);
//   const store = useDashboardStore();
//   const graphQLLoader = useGqlLoader();
//   const fileLoader = useFileLoader();
//   const isModeExported = process.env.NEXT_PUBLIC_APP_MODE === 'EXPORTED';

//   const { keyPair } = useKeyPairStore();
//   const { token } = useAuthStore();
//   const router = useRouter();
//   const sjCollectionStore = useSecureJsonCollectionsStore();

//   const syncQueueStore = useSyncQueueStore();

//   const synchronizer = useSynchronizer(
//     keyPair ?? ({} as KeyPair),
//     isModeExported ? fileLoader : graphQLLoader,
//     // 'Store init',
//   );

//   const SYNC_INTERVAL_MS = 5000;
//   useInterval(() => {
//     log.trace(
//       'Sync interval. Syncing:',
//       synchronizer?.isSyncing,
//       'Jobs:',
//       syncQueueStore.jobs,
//     );
//     if (syncQueueStore.jobs.length && !synchronizer?.isSyncing) {
//       log.trace('Sync interval: start sync.');
//       synchronizer?.sync();
//     }
//   }, SYNC_INTERVAL_MS);

//   const ADD_JOB_INTERVAL_MS = 1000;
//   useInterval(() => {
//     const notSyncedCollectionNames = Array.from(
//       sjCollectionStore.syncStatus.entries(),
//     )
//       .filter(([, status]) => !status)
//       .map(([name]) => name);

//     for (const notSyncedCollectionName of notSyncedCollectionNames) {
//       if (!sjCollectionStore.collections.get(notSyncedCollectionName)) {
//         continue;
//       }
//       log.trace('Add job for not synced collection:', notSyncedCollectionName);
//       syncQueueStore.addJob({
//         method: SyncQueueJobMethod.PUSH,
//         collections: [
//           {
//             collectionName: notSyncedCollectionName,
//             collection: sjCollectionStore.collections
//               .get(notSyncedCollectionName)!
//               .toString(),
//           },
//         ],
//       });
//       sjCollectionStore.setSyncStatus(notSyncedCollectionName, true);
//     }
//   }, ADD_JOB_INTERVAL_MS);

//   useEffect(() => {
//     if (!token && !isModeExported && isInitialized) {
//       setIsInitialized(false);
//       storeSetIsInitialized(false);
//     }

//     if (!isInitialized && keyPair?.privateKey && (token || isModeExported)) {
//       storeSetIsLoading(true);
//       log.info('Start stores initialization...');
