import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { IQuestion } from '@/_pages/auth/components/account-security/questions';
import { TwoFactorQuestionPlainObject } from '@/shared/generated/graphql';

type firstStepDataType = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

type FirstStepBeneficiaryData = {
  email: string;
  password: string;
};

export enum ERegisrationStep {
  ENTER_EMAIL = 'enter_email',
  CREATE_SEED = 'create_seed',
  CREATE_PIN_CODE = 'create_pin_code',
  SECRET_QUESTIONS = 'secret_questions',
  VERIFY_EMAIL = 'verify_email',
  CONGRATILATION = 'congratulation',
}

export type twoFactorQuestionsType = {
  question: string;
  answer: string;
};

type RegistrationStoreType = {
  beneficiaryJWT?: string | undefined;
  currentStep: ERegisrationStep;
  firstStepData: firstStepDataType;
  firstStepBeneficiaryData: FirstStepBeneficiaryData;
  publicKey: string;
  twoFactorQuestions: IQuestion[];
  twoFactorQuestionsAndAnswers: TwoFactorQuestionPlainObject[];
  phoneNumber: string | null;
  emailPin: string;
  setCurrentStep: (currentStep: ERegisrationStep) => void;
  setFirstStepData: (email: string, password: string, firstName: string, lastName: string) => void;
  setFirstStepBeneficiaryData: (email: string, password: string) => void;
  setPublicKey: (publicKey: string) => void;
  setTwoFactorQuestions: (questions: IQuestion[]) => void;
  setTwoFactorQuestionsAndAnswers: (twoFactorQuestions: twoFactorQuestionsType[]) => void;
  setBeneficiaryJWT: (jwt: string | undefined) => void;
  resetStorage: () => void;

  setEmailPin: (pin: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
};

export const useRegistrationStore = create(
  persist<RegistrationStoreType>(
    (set) => ({
      currentStep: ERegisrationStep.ENTER_EMAIL,
      beneficiaryJWT: undefined,
      firstStepData: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
      },
      firstStepBeneficiaryData: {
        email: '',
        password: '',
      },
      publicKey: '',
      twoFactorQuestions: [],
      twoFactorQuestionsAndAnswers: [],
      emailPin: '',
      phoneNumber: null,
      setCurrentStep: (currentStep) => set({ currentStep }),
      setBeneficiaryJWT: (beneficiaryJWT) => set({ beneficiaryJWT }),
      setFirstStepData: (email, password, firstName, lastName) =>
        set(() => ({
          firstStepData: {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
          },
        })),
      setFirstStepBeneficiaryData: (email, password) =>
        set(() => ({
          firstStepBeneficiaryData: {
            email: email,
            password: password,
          },
        })),
      setPublicKey: (publicKey) => set({ publicKey }),
      setTwoFactorQuestions: (twoFactorQuestions) => set({ twoFactorQuestions }),
      setTwoFactorQuestionsAndAnswers: (twoFactorQuestionsAndAnswers) =>
        set({ twoFactorQuestionsAndAnswers }),
      resetStorage: () =>
        set({
          currentStep: ERegisrationStep.ENTER_EMAIL,
          firstStepData: {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
          },
          publicKey: '',
          twoFactorQuestions: [],
          twoFactorQuestionsAndAnswers: [],
        }),
      setEmailPin: (emailPin) => set({ emailPin }),
      setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
    }),
    {
      name: 'register-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
