import { PublicKey } from '@/shared/lib/secure-json/core/crypto-core/types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';

export enum NotificationType {
  TEST_SCENARIO_LAUNCHED = 'TEST_SCENARIO_LAUNCHED',
  TEST_SCENARIO_ACCEPTED = 'TEST_SCENARIO_ACCEPTED',
  SCENARIO_CREATED = 'SCENARIO_CREATED',
  SCENARIO_ACTIVATED = 'SCENARIO_ACTIVATED',
  SCENARIO_RESOLVED = 'SCENARIO_RESOLVED',
  SCENARIO_DEACTIVATED = 'SCENARIO_DEACTIVATED',
  TRIGGER_SCENARIO_INFO = 'TRIGGER_SCENARIO_INFO',
  SCENARIO_EXECUTED = 'SCENARIO_EXECUTED',

  BENEFICIARY_CREATED = 'BENEFICIARY_CREATED',
  BENEFICIARY_UPDATED = 'BENEFICIARY_UPDATED',
  BENEFICIARY_DELETED = 'BENEFICIARY_DELETED',
  BENEFICIARY_SENT_INVITATION = 'BENEFICIARY_SENT_INVITATION',
  BENEFICIARY_ACCEPT_INVITATION = 'BENEFICIARY_ACCEPT_INVITATION',

  PASSWORD_CHANGE = 'PASSWORD_CHANGE',
  CHECK_PLAN = 'CHECK_PLAN',
  DATA_TRANSFERRED_TO_MY_HUB = 'DATA_TRANSFERRED_TO_MY_HUB',
  MY_HUB_CREATED = 'MY_HUB_CREATED',

  SENT_REQUEST_TO_ASSISTANT = 'SENT_REQUEST_TO_ASSISTANT',
  ASSISTANT_FILLED_FORM = 'ASSISTANT_FILLED_FORM',

  DOCUMENT_CREATED = 'DOCUMENT_CREATED',
  DOCUMENT_DELETED = 'DOCUMENT_DELETED',
  FILED_ADDED_TO_INBOX = 'FILED_ADDED_TO_INBOX',
  FILED_DELETED_FROM_INBOX = 'FILED_DELETED_FROM_INBOX',
  DOCUMENTS_BY_ASSISTANT = 'DOCUMENTS_BY_ASSISTANT',

  CONTACT_CREATED = 'CONTACT_CREATED',
  CONTACT_DELETED = 'CONTACT_DELETED',

  ASSET_DELETED = 'ASSET_DELETED',
  ASSET_CREATED = 'ASSET_CREATED',
  ASSET_UPDATED = 'ASSET_UPDATED',
}

export type NotificationDto = BaseDto &
  TNotificationType & {
    isRead: Record<PublicKey, boolean>;
    isVisible: boolean;
    date: Date;
  };

export type TNotificationType =
  | {
      type:
        | NotificationType.ASSET_CREATED
        | NotificationType.ASSET_DELETED
        | NotificationType.ASSET_UPDATED;
      assetId: DtoId;
    }
  | {
      type: NotificationType.CONTACT_CREATED | NotificationType.CONTACT_DELETED;
      contactId: DtoId;
    }
  | {
      type: NotificationType.DOCUMENT_DELETED | NotificationType.DOCUMENT_CREATED;
      documentId: DtoId;
    }
  | {
      type:
        | NotificationType.FILED_ADDED_TO_INBOX
        | NotificationType.FILED_DELETED_FROM_INBOX
        | NotificationType.DOCUMENTS_BY_ASSISTANT;
      amount: number;
    }
  | {
      type: NotificationType.SENT_REQUEST_TO_ASSISTANT;
      documentId: DtoId;
      assistantProfileId: DtoId;
    }
  | {
      type: NotificationType.ASSISTANT_FILLED_FORM;
      documentId: DtoId;
      assistantProfileId: DtoId;
      delegatingRequest: DtoId;
    }
  | {
      type:
        | NotificationType.PASSWORD_CHANGE
        | NotificationType.CHECK_PLAN
        | NotificationType.DATA_TRANSFERRED_TO_MY_HUB
        | NotificationType.MY_HUB_CREATED;
    }
  | {
      type:
        | NotificationType.BENEFICIARY_CREATED
        | NotificationType.BENEFICIARY_UPDATED
        | NotificationType.BENEFICIARY_DELETED
        | NotificationType.BENEFICIARY_ACCEPT_INVITATION
        | NotificationType.BENEFICIARY_SENT_INVITATION;
      beneficiaryId: DtoId;
    }
  | {
      type:
        | NotificationType.SCENARIO_CREATED
        | NotificationType.TEST_SCENARIO_LAUNCHED
        | NotificationType.TEST_SCENARIO_ACCEPTED
        | NotificationType.SCENARIO_ACTIVATED
        | NotificationType.SCENARIO_RESOLVED
        | NotificationType.SCENARIO_DEACTIVATED
        | NotificationType.TRIGGER_SCENARIO_INFO;
      scenarioId: DtoId;
    }
  | {
      type: NotificationType.SCENARIO_EXECUTED;
      scenarioId: DtoId;
      blockchainTransactionHash: string;
    };
