import { forwardRef } from 'react';
import { Input as AntdInput, InputRef } from 'antd';
import styled from 'styled-components';

import { IInput } from '@/shared/components/input/types';
import { COLORS } from '@/shared/constants/colors';

export const Input = forwardRef<InputRef, IInput>(({ placeholder, type, ...rest }, ref) => {
  return (
    <InputStyled ref={ref} placeholder={placeholder} type={type} autoComplete="off" {...rest} />
  );
});

Input.displayName = 'Input';

const InputStyled = styled(AntdInput)`
  input {
    border: 1px solid ${COLORS.colorBorder};
    padding: 10px 5px;
    margin-bottom: 10px;
  }
`;
