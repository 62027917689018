import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export type BirthCertificateDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  name: string;
  names: string;
  surnames: string;
  country: string;
  fullBirthName?: string;
  birthDate?: number;
  number?: string;
  dateOfIssue?: number;
  issuedBy?: string;
  other?: string;
  parent1?: string;
  parent2?: string;
  storageLocation?: string;
  comments?: string;
};

export type BirthCertificateDtoHelpType = {
  name: QuestionType.TEXT;
  names: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  fullBirthName?: QuestionType.TEXT;
  birthDate?: QuestionType.DATE_TIME;
  number?: QuestionType.TEXT;
  dateOfIssue?: QuestionType.DATE_TIME;
  issuedBy?: QuestionType.TEXT;
  other?: QuestionType.TEXT;
  parent1?: QuestionType.TEXT;
  parent2?: QuestionType.TEXT;
  attachments?: QuestionType.FILE_UPLOAD;
  storageLocation?: QuestionType.TEXT;
  comments?: QuestionType.TEXT_MULTILINE;
};

type BirthCertificateFormType = TDtoWrapper<BirthCertificateDtoType, BirthCertificateDtoHelpType>;

export const BirthCertificateForm: BirthCertificateFormType = {
  name: {
    type: QuestionType.TEXT,
    label: '*Document name',
    required: true,
    value: '',
    tooltip: 'Enter a document name for display within the system',
  },
  names: {
    type: QuestionType.TEXT,
    label: '*Name(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  surnames: {
    type: QuestionType.TEXT,
    label: '*Surname(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  fullBirthName: {
    type: QuestionType.TEXT,
    label: 'Full birth name',
  },
  birthDate: {
    type: QuestionType.DATE_TIME,
    label: 'Birth date',
  },
  number: {
    type: QuestionType.TEXT,
    label: 'Document number',
  },
  dateOfIssue: {
    type: QuestionType.DATE_TIME,
    label: 'Start date',
  },
  issuedBy: {
    type: QuestionType.TEXT,
    label: 'Issued by',
  },
  other: {
    type: QuestionType.TEXT,
    label: 'Other data',
  },
  parent1: {
    type: QuestionType.TEXT,
    label: 'Parent 1 (Full name)',
  },
  parent2: {
    type: QuestionType.TEXT,
    label: 'Parent 2 (Full name)',
  },
  attachments: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Document scan',
  },
  storageLocation: {
    type: QuestionType.TEXT,
    label: 'Storage location',
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
};
