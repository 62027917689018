import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button, Radio, Tabs, Typography } from 'antd';

import { Drawer } from '@/shared/components/drawer';
import { Icon } from '@/shared/components/icon';
import { Row } from '@/shared/components/layout';
import { COLORS } from '@/shared/constants/colors';

import { Category } from './category';
import { InviteDrawer } from './inviteDrawer';
import {
  isNextOwnerTab,
  ModalInviteUser,
  NextOwnerTabs,
  NextOwnerUser,
  SetInvitedUsers,
} from './type';
const { TabPane } = Tabs;

type NextOwnersDrawerProps = {
  filteredUser: Array<NextOwnerUser>;
  handleSelect: (value?: string) => void;
  invitedUsers: Array<string>;
  isModalOpen: boolean;
  label: string;
  notInvitedUser: Array<NextOwnerUser>;
  notRegisteredUser: Array<NextOwnerUser>;
  onAddNewClick: () => Promise<void>;
  onSelectClick: VoidFunction;
  refetchNestOwners: VoidFunction;
  registeredUser: Array<NextOwnerUser>;
  searchInputHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  selectedItem?: string;
  setInvitedUsers: SetInvitedUsers;
  setIsModalOpen: (arg: boolean) => void;
};

const keys: Record<NextOwnerTabs, string> = {
  all: 'all',
  invited: 'invited',
  notInvited: 'notInvited',
  registered: 'registered',
};

export const NextOwnersDrawer: FC<NextOwnersDrawerProps> = ({
  filteredUser,
  handleSelect,
  invitedUsers,
  isModalOpen,
  label,
  notInvitedUser,
  notRegisteredUser,
  onAddNewClick,
  onSelectClick,
  refetchNestOwners,
  registeredUser,
  searchInputHandler,
  searchValue,
  selectedItem,
  setInvitedUsers,
  setIsModalOpen,
}) => {
  const router = useRouter();
  const [activeTab, setActiveTab] = useState<NextOwnerTabs>('all');

  const [modalInviteUserState, setModalInviteUserState] = useState<ModalInviteUser>({
    isOpen: false,
  });

  // After create new user change tab
  useEffect(() => {
    const { isInvited, isFastAdded } = router.query;
    const query = { ...router.query };
    delete query.isInvited;
    delete query.isFastAdded;

    if (isFastAdded === 'true') {
      setActiveTab(isInvited === 'true' ? 'invited' : 'notInvited');

      router.replace(
        {
          pathname: router.pathname,
          query: query,
        },
        undefined,
        { shallow: true },
      );
    }
  }, [router]);

  return (
    <>
      <Drawer
        height="auto"
        placement="bottom"
        open={isModalOpen}
        styles={{
          body: {
            paddingBottom: 0,
          },
        }}
        onClose={() => setIsModalOpen(false)}
        closeIcon={false}
        footer={
          <Row gap={12} paddingX={14}>
            <Button size="large" onClick={onAddNewClick} style={{ flex: 1 }}>
              Add New
            </Button>

            <Button
              type="primary"
              size="large"
              onClick={onSelectClick}
              disabled={!selectedItem}
              style={{ flex: 1 }}
            >
              Select
            </Button>
          </Row>
        }
      >
        <Row justifyContent="space-between" alignCenter nowrap>
          <Typography.Title level={3} style={{ margin: 0 }}>
            {label}
          </Typography.Title>

          <Icon
            icon="close"
            cursorPointer
            onClick={() => {
              setIsModalOpen(false);
              localStorage.removeItem(`new-family-member-added`);
            }}
            color={COLORS.colorLink}
          />
        </Row>

        <Radio.Group
          onChange={(e) => handleSelect(e.target.value)}
          style={{ width: '100%' }}
          value={selectedItem}
        >
          <Tabs
            activeKey={activeTab}
            defaultActiveKey="all"
            onChange={(tabName) => {
              if (isNextOwnerTab(tabName)) {
                setActiveTab(tabName);
              }

              refetchNestOwners();
            }}
            size="large"
            tabBarGutter={16}
            tabPosition="top"
            type="line"
          >
            <TabPane tab={`All (${filteredUser.length})`} key={keys.all}>
              <Category
                dataSource={filteredUser}
                handleSelect={handleSelect}
                invitedUsers={invitedUsers}
                isShowStatus
                searchInputHandler={searchInputHandler}
                searchValue={searchValue}
                setModalInviteUserState={setModalInviteUserState}
              />
            </TabPane>

            <TabPane tab={`Registered (${registeredUser.length})`} key={keys.registered}>
              <Category
                dataSource={registeredUser}
                descriptionInfo={{
                  title: 'What is Registered?',
                  text: 'Family Member has been invited, and completed the registration in Owner.One. An algorithm for such Family Member can be created and activated. This algorithm can also be tested.',
                }}
                handleSelect={handleSelect}
                invitedUsers={invitedUsers}
                searchInputHandler={searchInputHandler}
                searchValue={searchValue}
                setModalInviteUserState={setModalInviteUserState}
              />
            </TabPane>

            <TabPane tab={`Not Registered (${notRegisteredUser.length})`} key={keys.invited}>
              <Category
                dataSource={notRegisteredUser}
                descriptionInfo={{
                  title: 'What is Not Registered?',
                  text: `Family Member has been invited, but he hasn't completed the registration in Owner.One yet. Please, press the button "Resend invite" to repeat invite. An algorithm for such Family Member can be created and activated. But it will be on hold until registration is completed.`,
                }}
                handleSelect={handleSelect}
                invitedUsers={invitedUsers}
                searchInputHandler={searchInputHandler}
                searchValue={searchValue}
                setModalInviteUserState={setModalInviteUserState}
              />
            </TabPane>

            <TabPane tab={`Not Invited (${notInvitedUser.length})`} key={keys.notInvited}>
              <Category
                dataSource={notInvitedUser}
                descriptionInfo={{
                  title: 'What is Not Invited?',
                  text: `Family Member hasn't been invited, and he hasn't completed the registration in Owner.One yet. Please, press the button "Send invite" to send an invitation. An algorithm for such Family Member can be created and activated. But it will be on hold until registration is completed.`,
                }}
                handleSelect={handleSelect}
                invitedUsers={invitedUsers}
                searchInputHandler={searchInputHandler}
                searchValue={searchValue}
                setModalInviteUserState={setModalInviteUserState}
              />
            </TabPane>
          </Tabs>
        </Radio.Group>
      </Drawer>

      <InviteDrawer
        modalInviteUserInfo={modalInviteUserState}
        setInvitedUsers={setInvitedUsers}
        setModalInviteUserState={setModalInviteUserState}
      />
    </>
  );
};
