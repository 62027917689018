export const MAX_FILES_COUNT = 30;
export const MAX_SIZE_MB = 50;

// export const allowedExtensions = [
//   'heic',
//   'jpeg',
//   'jpg',
//   'png',
//   'doc',
//   'docx',
//   'rtf',
//   'key',
//   'odt',
//   'pdf',
//   'tiff',
//   'ppt',
//   'pptx',
//   'pps',
//   'ppsx',
//   'xls',
//   'xlsx',
//   'csv',
//   'txt',
// ];

export const allowedMimeTypes = [
  'image/heic',
  'image/heif',
  'image/jpeg',
  'image/png',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/rtf',
  'application/vnd.apple.keynote',
  'application/vnd.oasis.opendocument.text',
  'application/pdf',
  'image/tiff',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'text/plain',
];

export const validateType = (mimeType: string) => {
  // const fileExtension = String(ext||'').toLowerCase();

  // const isExtensionAllowed = allowedExtensions.includes(fileExtension);
  // return isExtensionAllowed && isMimeTypeAllowed;
  return allowedMimeTypes.includes(mimeType);
};

export const validateSize = (size: number) => {
  return size / 1024 / 1024 < MAX_SIZE_MB;
};
