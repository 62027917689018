import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { FormLabelItem, TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  PrivateDocumentCategory,
  PrivateDocumentDto,
} from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { AssetCategory } from '../../sj-orm/models/asset.dto';
import { ContactType } from '../../sj-orm/models/contact.dto';

export type CommercialRealEstate = CommercialRealEstateFormPart1Type &
  CommercialRealEstateFormPart2Type &
  AssetsOwnershipPartType &
  AssetsStatisticsPartType;

export enum CommercialRealEstateType {
  retail = 'retail',
  office = 'office',
  warehouse = 'warehouse',
  production = 'production',
  recreation = 'recreation',
  agricultural = 'agricultural',
  condominium = 'condominium',
  other = 'other',
}

const CommercialRealEstateList = [
  {
    key: CommercialRealEstateType.retail,
    label: 'forms_assets:commercialRealEstate.enums.retail',
  },
  {
    key: CommercialRealEstateType.office,
    label: 'forms_assets:commercialRealEstate.enums.office',
  },
  {
    key: CommercialRealEstateType.warehouse,
    label: 'forms_assets:commercialRealEstate.enums.warehouse',
  },
  {
    key: CommercialRealEstateType.production,
    label: 'forms_assets:commercialRealEstate.enums.production',
  },
  {
    key: CommercialRealEstateType.recreation,
    label: 'forms_assets:commercialRealEstate.enums.recreation',
  },
  {
    key: CommercialRealEstateType.agricultural,
    label: 'forms_assets:commercialRealEstate.enums.agricultural',
  },
  {
    key: CommercialRealEstateType.condominium,
    label: 'forms_assets:commercialRealEstate.enums.condominium',
  },
  {
    key: CommercialRealEstateType.other,
    label: 'forms_assets:commercialRealEstate.enums.other',
  },
];

type CommercialRealEstatePart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  type?: CommercialRealEstateType;
  otherType: string;
  purchaseValue: number;
  district: string;
  addressLabel?: string;
  zipCode: string;
  city: string;
  street: string;
  buildingNumber: string;
  unitOfficeNumber?: string;
};
type CommercialRealEstatePart2 = Omit<BaseDto, 'id'> & {
  commercialRealEstateNumberName?: string;
  commercialRealEstateNumber?: number;
  // otherAddressInfo?: string;
  commencementDate?: string;
  areaOfTheApartments?: number;
  compositionOfObjects?: string;
  landPlot?: boolean;
  chooseLandPlot?: DtoId[];
  landPlotSquare?: number;
  contacts?: DtoId[];
  powersOfAttorney?: DtoId[];
  otherDocuments?: DtoId[];
  comments?: string;
};

type CommercialRealEstateHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  type?: QuestionType.SELECT_FROM_LIST;
  otherType: QuestionType.TEXT;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  district: QuestionType.TEXT;
  zipCode: QuestionType.TEXT;
  city: QuestionType.TEXT;
  street: QuestionType.TEXT;
  buildingNumber: QuestionType.TEXT;
  unitOfficeNumber?: QuestionType.TEXT;
};

type CommercialRealEstateHelpPart2 = {
  commercialRealEstateNumberName?: QuestionType.TEXT;
  commercialRealEstateNumber?: QuestionType.NUMBER;
  // otherAddressInfo?: QuestionType.TEXT_MULTILINE;
  constructionDate?: QuestionType.DATE_TIME;
  apartmentSquare?: QuestionType.NUMBER;
  compositionOfObjects?: QuestionType.TEXT;
  landPlot?: QuestionType.CHECKBOX;
  chooseLandPlot?: QuestionType.PICK_ARRAY_OF_DTO;
  landPlotSquare?: QuestionType.NUMBER;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  contacts?: QuestionType.PICK_ARRAY_OF_DTO;
  comments?: QuestionType.TEXT_MULTILINE;
};

type CommercialRealEstateFormType = [
  CommercialRealEstateFormPart1Type,
  CommercialRealEstateFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
];

type CommercialRealEstateFormPart1Type = TDtoWrapper<
  CommercialRealEstatePart1,
  CommercialRealEstateHelpPart1
>;
type CommercialRealEstateFormPart2Type = TDtoWrapper<
  CommercialRealEstatePart2,
  CommercialRealEstateHelpPart2
>;

const CommercialRealEstateFormPart1: CommercialRealEstateFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup.string().required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:commercialRealEstate.inputs.type.label',
    value: CommercialRealEstateType.retail,
    items: CommercialRealEstateList,
  },
  otherType: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commercialRealEstate.inputs.otherType.label',
    showIf: {
      type: {
        value: CommercialRealEstateType.other,
      },
    },
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:commercialRealEstate.inputs.currentMarketPrice.label',
    tooltip: 'forms_assets:commercialRealEstate.inputs.currentMarketPrice.tooltip',
    equivalentValueTooltip: 'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  addressLabel: {
    type: FormLabelItem.label,
    label: 'forms_assets:common.inputs.addressLabel.label',
    tooltip: 'forms_assets:common.inputs.addressLabel.tooltip',
  },
  district: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:commercialRealEstate.inputs.district.label',
    },
    required: false,
    value: '',
  },
  zipCode: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.zipCode.label',
    },
    required: false,
    value: '',
  },
  city: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.city.label',
    },
    required: false,
    value: '',
  },
  street: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.street.label',
    },
    required: false,
    value: '',
  },
  buildingNumber: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.buildingNumber.label',
    },
    required: false,
    value: '',
  },
  unitOfficeNumber: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.unitOfficeNumber.label',
    },
  },
};

const CommercialRealEstateFormPart2: CommercialRealEstateFormPart2Type = {
  commercialRealEstateNumberName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commercialRealEstate.inputs.commercialRealEstateNumberName.label',
    order: 1,
  },
  commercialRealEstateNumber: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:commercialRealEstate.inputs.commercialRealEstateNumber.label',
    order: 2,
  },
  // otherAddressInfo: {
  //   type: QuestionType.TEXT_MULTILINE,
  //   label: 'forms_assets:commercialRealEstate.inputs.otherAddressInfo.label',
  // },
  commencementDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:commercialRealEstate.inputs.commencementDate.label',
    tooltip: 'forms_assets:commercialRealEstate.inputs.commencementDate.tooltip',
    order: 3,
  },
  areaOfTheApartments: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:commercialRealEstate.inputs.areaOfTheApartments.label',
    order: 4,
  },
  compositionOfObjects: {
    type: QuestionType.TEXT,
    label: 'forms_assets:commercialRealEstate.inputs.compositionOfObjects.label',
    order: 5,
  },
  landPlot: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:commercialRealEstate.inputs.landPlot.label',
    order: 6,
  },
  chooseLandPlot: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:commercialRealEstate.inputs.chooseLandPlot.label',
    items: 'assets',
    showIf: {
      landPlot: {
        value: true,
      },
    },
    order: 7,
    dtoType: AssetCategory.LAND,
  },
  landPlotSquare: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:commercialRealEstate.inputs.landPlotSquare.label',
    showIf: {
      landPlot: {
        value: true,
      },
    },
    order: 8,
  },
  contacts: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.contacts.label',
    items: 'contacts',
    mode: 'multiple',
    order: 9,
    dtoType: ContactType.BANK,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.powersOfAttorney.label',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'forms_assets:common.inputs.powersOfAttorney.tooltip',
    filter: (doc) =>
      (doc as unknown as PrivateDocumentDto).privateCategory === PrivateDocumentCategory.POWERS,
    dtoType: PrivateDocumentCategory.POWERS,
  },
  otherDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherDocuments.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:common.inputs.comments.label',
  },
};

const CommercialRealEstateForm: CommercialRealEstateFormType = [
  CommercialRealEstateFormPart1,
  CommercialRealEstateFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgent',
    'taxAgentOther',
    'taxAgentWithBroker',
    'taxAgentWithBrokerOther',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
];

export { CommercialRealEstateForm };
