import { useMemo, useState } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';

import { useSynchronizerStore } from '@/_app/providers/synchronizer/synchronizer.store';
import { useAssistantStore } from '@/entities/assistant';
import { useMnemonicPhraseStore } from '@/entities/mnemonic';
import { useDashboard } from '@/features/dashboard/hooks/use-dashboard';
import {
  AccountStatus,
  useLoginViaPersonalAccountCodeMutation,
  useLogoutMutation,
  useWhoamiLazyQuery,
} from '@/shared/generated/graphql';
import { ProfileType } from '@/shared/generated/sdk';
import { useInitProfile } from '@/shared/hooks/useInitProfile';
import { EncryptionType } from '@/shared/lib/secure-json/core/crypto-core/types';
import { useSecureJsonCollectionsStore } from '@/shared/lib/stores/secure-json-collections.store';
import { useAuthStore } from '@/shared/store/auth.store';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { useProfileStore } from '@/shared/store/profile.store';
import { ERegisrationStep } from '@/shared/store/registration.store';
import { useSettingsStore } from '@/shared/store/settings.store';
import { log } from '@/shared/utils/log';

// SSO auth legacy
export const useAuth = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();

  const [loginViaPa] = useLoginViaPersonalAccountCodeMutation();
  const [whoami] = useWhoamiLazyQuery();
  const [logout] = useLogoutMutation();
  const { initProfile } = useInitProfile();

  const { setToken, setAccount } = useAuthStore();
  const [setEncryptionType, setProfileType, clearWhoamiStore] = useProfileStore((s) => [
    s.setEncryptionType,
    s.setProfileType,
    s.clear,
  ]);
  const { mnemonicPhrase } = useMnemonicPhraseStore();
  const { clear: clearDashboard } = useDashboard();
  const clearAuthStore = useAuthStore((s) => s.clear);
  const clearSettingsStore = useSettingsStore((s) => s.clear);
  const clearKeyPairStore = useKeyPairStore((s) => s.clear);
  const clearSynchronizerStore = useSynchronizerStore((s) => s.clear);
  const clearAssistantStore = useAssistantStore((s) => s.clear);

  const clearSecureJsonCollectionsStore = useSecureJsonCollectionsStore((s) => s.clear);

  const goRegistration = async () => {
    await router.push(`/auth/register?step=${ERegisrationStep.CREATE_SEED}`);
  };

  const goLogin = async () => {
    const isEmptyMnemonic = !mnemonicPhrase || mnemonicPhrase.length === 0;
    if (isEmptyMnemonic) {
      if (router.query.reset) {
        await router.push(
          {
            pathname: '/auth/login/mnemonic-phrase',
            query: { reset: true },
          },
          undefined,
        );
        // TODO why only for router.query
        setLoading(false);

        return;
      } else {
        await router.push('/auth/login/mnemonic-phrase', undefined);
      }
    }
  };

  const appLoginViaPa = async (authGrantToken: string) => {
    let loginData;

    try {
      const resLogin = await loginViaPa({
        variables: {
          code: authGrantToken,
        },
      });

      loginData = resLogin.data?.loginViaPersonalAccountCode;
    } catch (er) {
      log.error('[useAuth] loginViaPa');
    }

    if (!loginData || !loginData.profile) {
      return false;
    }

    const profile = loginData.profile;
    const encryptionType = (profile.encryptionType?.title ?? 'ecies') as EncryptionType;

    setToken(loginData.token);
    setAccount(loginData.account);
    setProfileType(ProfileType.MainUser); // TODO its auth flow for CO
    setEncryptionType(encryptionType);

    const isRegistration = profile.status !== AccountStatus.Active;
    if (isRegistration) {
      await goRegistration();
    } else {
      await initProfile(loginData.token);
      await goLogin();
    }

    return true;
  };

  const appLogout = async (redirectURL?: Parameters<NextRouter['push']>['0']) => {
    setLoading(true);
    const resultWhoami = await whoami();

    if (
      resultWhoami &&
      resultWhoami.data &&
      resultWhoami.data.whoami &&
      resultWhoami?.data.whoami.sessions
    ) {
      try {
        await logout({
          variables: {
            sessionIds: [resultWhoami?.data?.whoami?.sessions[0]?.id],
          },
        });
      } catch (error) {
        console.log('Logout error');
        console.error(error);
      }
    }

    clearKeyPairStore();
    clearDashboard();
    clearAuthStore();
    clearSynchronizerStore();
    clearSettingsStore();
    clearSecureJsonCollectionsStore();
    clearAssistantStore();
    clearWhoamiStore();
    clearSynchronizerStore();
    localStorage.clear();
    sessionStorage.clear();
    await router.push(redirectURL ?? '/');
    await apolloClient.resetStore();
  };

  return useMemo(
    () => ({
      appLoginViaPa,
      appLogout,
      loading,
    }),
    [loading, appLoginViaPa],
  );
};
