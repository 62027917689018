import { FC } from 'react';
import { Divider, Drawer, Flex, notification, Typography } from 'antd';
import styled from 'styled-components';

import { Button } from '@/shared/components/button';
import { Icon } from '@/shared/components/icon';
import { Input } from '@/shared/components/input';
import { Row } from '@/shared/components/layout';
import { Tooltip } from '@/shared/components/tooltip';
import { COLORS } from '@/shared/constants/colors';
import { useSendNewInviteToBeneficiaryMutation } from '@/shared/generated/graphql';

import { InviteInfo } from './inviteInfo';
import { ModalInviteUser, SetInvitedUsers, SetInviteUser } from './type';
const { Text, Title } = Typography;

type InviteProps = {
  modalInviteUserInfo: ModalInviteUser;
  setInvitedUsers: SetInvitedUsers;
  setModalInviteUserState: SetInviteUser;
};

export const InviteDrawer: FC<InviteProps> = ({
  modalInviteUserInfo,
  setInvitedUsers,
  setModalInviteUserState,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [sendInvite, { loading }] = useSendNewInviteToBeneficiaryMutation();
  const inviteLink = `${process.env.NEXT_PUBLIC_SELF_URL_BASE}?beneficiarydata=${modalInviteUserInfo.code}`;

  const onClose = () =>
    setModalInviteUserState({
      isOpen: false,
    });

  const handleCopy = async () => {
    try {
      navigator.clipboard.writeText(inviteLink);

      api.success({
        message: 'Invite link was copied',
        placement: 'top',
      });
    } catch (error) {
      api.error({
        message: 'Failed to copy',
        placement: 'top',
      });
    }
  };

  const sendInviteBeneficiary = async () => {
    try {
      if (modalInviteUserInfo.id && typeof modalInviteUserInfo.email === 'string') {
        await sendInvite({ variables: { id: Number(modalInviteUserInfo.id) } });

        localStorage.setItem(`${modalInviteUserInfo.email}-invited`, String(new Date().getTime()));

        onClose();

        api.success({
          message: 'Family member invited to Owner.One successfully',
          placement: 'top',
        });

        setInvitedUsers((users: Array<string>) => {
          return [...users, modalInviteUserInfo.email!];
        });
      }
    } catch (error) {
      api.error({
        message: 'Failed to invited',
        placement: 'top',
      });
    }
  };

  return (
    <Drawer
      open={modalInviteUserInfo.isOpen}
      placement="bottom"
      closeIcon={false}
      height="auto"
      footer={
        <Flex>
          <CancelButton text="Cancel" type="link" onClick={onClose} />

          <Button
            text={modalInviteUserInfo.isFirstInvite ? 'Invite' : 'Resend'}
            disabled={loading}
            onClick={sendInviteBeneficiary}
          />
        </Flex>
      }
    >
      {contextHolder}
      <Row justifyContent="space-between" alignCenter nowrap>
        <Title level={3} style={{ margin: 0 }}>
          {modalInviteUserInfo.isFirstInvite ? 'Invite Family Member?' : 'Resend invite?'}
        </Title>

        <Icon icon="close" cursorPointer onClick={onClose} color={COLORS.colorLink} />
      </Row>

      <Divider style={{ marginTop: '16px' }} />

      <Flex vertical gap={12}>
        <StyledText>
          By clicking the button, you resend an email invitation to Owner.One for Family Member.
        </StyledText>

        <InviteInfo email={String(modalInviteUserInfo.email)} />

        <label>
          <StyledText>Share invite link</StyledText>

          <Tooltip
            title={
              'Share this link with the Family Member to create a secure account. Their status will update upon registration.'
            }
          />

          <Flex gap={12}>
            <StyledInput placeholder={inviteLink} value={inviteLink} readOnly />

            <CopyButton onClick={handleCopy} icon={<SearchIcon />} text="" type="default" />
          </Flex>
        </label>
      </Flex>
    </Drawer>
  );
};

const StyledText = styled(Text)`
  &&& {
    font-family: 'Avenir Next Cyr';
  }
`;

const StyledInput = styled(Input)`
  &&& {
    color: #0055ff;
    text-overflow: ellipsis;
  }
`;

const CancelButton = styled(Button)`
  &&& {
    color: #0055ff;
  }
`;

const CopyButton = styled(Button)`
  &&& {
    width: 54px;
  }
`;

const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M14.375 5H3.125C2.95924 5 2.80027 5.06585 2.68306 5.18306C2.56585 5.30027 2.5 5.45924 2.5 5.625V16.875C2.5 17.0408 2.56585 17.1997 2.68306 17.3169C2.80027 17.4342 2.95924 17.5 3.125 17.5H14.375C14.5408 17.5 14.6997 17.4342 14.8169 17.3169C14.9342 17.1997 15 17.0408 15 16.875V5.625C15 5.45924 14.9342 5.30027 14.8169 5.18306C14.6997 5.06585 14.5408 5 14.375 5ZM13.75 16.25H3.75V6.25H13.75V16.25ZM17.5 3.125V14.375C17.5 14.5408 17.4342 14.6997 17.3169 14.8169C17.1997 14.9342 17.0408 15 16.875 15C16.7092 15 16.5503 14.9342 16.4331 14.8169C16.3158 14.6997 16.25 14.5408 16.25 14.375V3.75H5.625C5.45924 3.75 5.30027 3.68415 5.18306 3.56694C5.06585 3.44973 5 3.29076 5 3.125C5 2.95924 5.06585 2.80027 5.18306 2.68306C5.30027 2.56585 5.45924 2.5 5.625 2.5H16.875C17.0408 2.5 17.1997 2.56585 17.3169 2.68306C17.4342 2.80027 17.5 2.95924 17.5 3.125Z"
      fill="#445371"
    />
  </svg>
);
