import styled from 'styled-components';

export const EnvList = () => (
  <Container>
    <div>NEXT_PUBLIC_DEBUG: {process.env.NEXT_PUBLIC_DEBUG}</div>
    <div>NEXT_PUBLIC_SELF_URL_BASE: {process.env.NEXT_PUBLIC_SELF_URL_BASE}</div>
    <div>NEXT_PUBLIC_COLLECT_LOGS: {process.env.NEXT_PUBLIC_COLLECT_LOGS}</div>
    <div>NEXT_PUBLIC_GRAPHQL_API_URL: {process.env.NEXT_PUBLIC_GRAPHQL_API_URL}</div>
    <div>
      NEXT_PUBLIC_MERGED_GRAPHQL_REQUESTS_ENABLED:{' '}
      {process.env.NEXT_PUBLIC_MERGED_GRAPHQL_REQUESTS_ENABLED}
    </div>
    <div>
      NEXT_PUBLIC_MERGED_GRAPHQL_REQUESTS_BATCH_MAX:{' '}
      {process.env.NEXT_PUBLIC_MERGED_GRAPHQL_REQUESTS_BATCH_MAX}
    </div>
    <div>
      NEXT_PUBLIC_MERGED_GRAPHQL_REQUESTS_BATCH_INTERVAL:{' '}
      {process.env.NEXT_PUBLIC_MERGED_GRAPHQL_REQUESTS_BATCH_INTERVAL}
    </div>
    <div>NEXT_PUBLIC_PERSIST_CACHE: {process.env.NEXT_PUBLIC_PERSIST_CACHE}</div>
    <div>NEXT_PUBLIC_APP_MODE: {process.env.NEXT_PUBLIC_APP_MODE}</div>
    <div>NEXT_PUBLIC_PA_URL: {process.env.NEXT_PUBLIC_PA_URL}</div>
    <div>NEXT_PUBLIC_APP_URL: {process.env.NEXT_PUBLIC_APP_URL}</div>
    <div>NEXT_PUBLIC_ENABLE_DOCUMENTS_OCR: {process.env.NEXT_PUBLIC_ENABLE_DOCUMENTS_OCR}</div>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
