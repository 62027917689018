import { ReactNode, useEffect, useRef } from 'react';

interface LoadMoreContainerProps {
  children: ReactNode;
  onLoadMore: () => void;
}

let observerRefValue: Element | null = null;

export const LoadMoreContainer = ({ children, onLoadMore }: LoadMoreContainerProps) => {
  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onLoadMore();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
      observerRefValue = observerTarget.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [observerTarget, onLoadMore]);

  return (
    <>
      {children}
      <div ref={observerTarget}></div>
    </>
  );
};
