import { startTransition, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Typography } from 'antd';

import { useMnemonicPhraseStore } from '@/entities/mnemonic/store/mnemonic-phrase.store';
import { useMyHubProfile } from '@/features/my-hub/myHubProfileStore';
import { Button, ErrorText } from '@/shared/components';
import { Container, Row } from '@/shared/components/layout';
import { CryptoCore } from '@/shared/lib/secure-json/core/crypto-core';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { EnterMnemonicPhrase } from '@/widgets/mnemonic-phrase/enter-mnemonic-phrase';

const ORIGIN = process.env.NEXT_PUBLIC_SELF_URL_BASE as string;

const MainPageForExportedMode = () => {
  const { push } = useRouter();
  const { setKeyPair } = useKeyPairStore();
  const { inputPhrase: mnemonicPhrase } = useMnemonicPhraseStore();
  const { setData } = useMyHubProfile();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [pubKey, setPubKey] = useState('');

  const handleSave = () => startTransition(() => setLoading(true));

  const savePhrase = async () => {
    const mnemonic = mnemonicPhrase.trim();
    if (!mnemonic) {
      throw new Error('Please enter mnemonic');
    }

    const encryptionType = 'ecies'; // TODO crypto should get from profile data (database.json)
    const cryptoCore = new CryptoCore(encryptionType);
    const { publicKey, privateKey } = await cryptoCore.mnemonicToKeyPair(mnemonic);
    if (publicKey !== pubKey) {
      throw new Error('Wrong mnemonic phrase!');
    }
    setKeyPair({ privateKey, publicKey }, encryptionType);

    await push({ pathname: '/dashboard' }, undefined, { shallow: true });
  };

  const load = () =>
    savePhrase().catch((e) => {
      setLoading(false);
      setError(e.message);
    });

  useEffect(() => {
    const fetchPubKey = async () => {
      const url = `${ORIGIN}/database/database.json`;

      const response = await fetch(url);
      const data = await response.json();
      const mainUserData = data.mainUserData;
      const profile = data.profile;

      if (mainUserData) {
        setPubKey(mainUserData.publicKey ?? '');
      }

      if (profile) {
        setData({
          firstName: profile.firstName ?? '',
          lastName: profile.lastName ?? '',
        });
      }
    };

    fetchPubKey();
  }, []);

  useEffect(() => {
    if (loading) load();
  }, [loading]);

  return (
    <Row justifyContent="center" paddingTop={35} paddingBottom={20} width="100%">
      <Row
        maxWidth={390}
        width="100%"
        paddingX={37}
        direction="column"
        justifyContent="space-between"
      >
        <div>
          <Typography.Title level={3}>Welcome to MyHub</Typography.Title>
          <Typography.Title level={4} style={{ marginTop: '20px' }}>
            Enter your Owner.One signup mnemonic phrase
          </Typography.Title>

          <Container marginTop={57} onClick={loading ? undefined : () => setError('')}>
            <EnterMnemonicPhrase />
          </Container>
        </div>

        <Row alignCenter justifyContent={'center'} marginTop={10}>
          {error ? <ErrorText>{error}</ErrorText> : null}
        </Row>
        <Container marginTop={77}>
          <Button text="Confirm" onClick={handleSave} disabled={loading} />
        </Container>
      </Row>
    </Row>
  );
};

export default MainPageForExportedMode;
