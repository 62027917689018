import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyQueryExecFunction } from '@apollo/client';
import dayjs from 'dayjs';

import { NotificationContent } from '@/entities/notification/hooks/use-notifications/use-notifications.types';
import { Exact, ScenarioQuery, useScenarioLazyQuery } from '@/shared/generated/graphql';
import { PublicKey } from '@/shared/lib/secure-json/core/crypto-core/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { SJDatabase } from '@/shared/lib/sj-orm/core';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import {
  NotificationDto,
  NotificationType,
  TNotificationType,
} from '@/shared/lib/sj-orm/models/notification.dto';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { lowerTextAndCapitalizeFirstLetter } from '@/shared/utils/misc';

export const useNotifications = () => {
  const sjDb = useSJDatabase();
  const { keyPair } = useKeyPairStore();
  const [scenarioLazyQuery] = useScenarioLazyQuery();
  const { t } = useTranslation(['notifications']);
  const [total, setTotal] = useState(0);

  const notificationsList = useCallback(
    async (isVisible = true, cursor?: { from: number; to: number }) => {
      const items = (
        sjDb.collections.notification?.findMany(
          (notification) => notification.isVisible === isVisible,
        ) || []
      ).reverse();

      setTotal(items.length);

      if (!items.length) return [];

      const result: (NotificationContent & {
        id: string;
        isRead: boolean;
        type: NotificationType;
      })[] = [];

      const partItems = cursor ? items.slice(cursor.from, cursor.to + 1) : items;

      for (const item of partItems) {
        const notification = await resolveNotificationType(item, sjDb, scenarioLazyQuery, t);
        result.push({
          ...notification,
          id: item.id,
          isRead: item.isRead[keyPair?.publicKey || ''],
          type: item.type,
        });
      }

      return result;
    },
    [sjDb, keyPair],
  );

  const getNotification = useCallback(
    async (id: string) => {
      const notification = sjDb.collections.notification?.findOne(
        (_notification) => _notification.id === id,
      );
      return notification
        ? {
            ...(await resolveNotificationType(notification, sjDb, scenarioLazyQuery, t)),
            id: notification.id,
            isRead: notification.isRead[keyPair?.publicKey || ''],
          }
        : undefined;
    },
    [sjDb, keyPair],
  );

  const updateRead = useCallback(
    (id: string, state: boolean = true, publicKey?: string) => {
      try {
        const notification = sjDb.collections.notification?.findOne(
          (_notification) => _notification.id === id,
        );
        if (!notification) return false;

        const key = publicKey ?? keyPair?.publicKey;

        if (!key) return false;

        if (notification.isRead[key] !== state) {
          notification.isRead[key] = state;

          sjDb.collections.notification?.update(notification);
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [sjDb, keyPair],
  );

  const createNotification = useCallback(
    (data: TNotificationType, isVisible = true, additionalPublicKeys?: PublicKey[]) => {
      const publicKeys = additionalPublicKeys ? additionalPublicKeys : [];

      if (keyPair) {
        publicKeys.unshift(keyPair.publicKey);
      }

      const notification: NotificationDto = Object.assign({}, data, {
        id: sjDb.generateId(CollectionName.NOTIFICATION),
        relatedDtoList: [],
        isRead: {},
        isVisible,
        date: new Date(),
      });
      return sjDb.collections.notification?.create(notification, publicKeys);
    },
    [keyPair, sjDb],
  );

  return useMemo(
    () => ({
      notificationsList,
      getNotification,
      updateRead,
      createNotification,
      total,
    }),
    [notificationsList, getNotification, updateRead, createNotification, total],
  );
};

// eslint-disable-next-line complexity
const resolveNotificationType = async (
  notification: NotificationDto,
  sjDb: SJDatabase,
  scenarioLazyQuery: LazyQueryExecFunction<ScenarioQuery, Exact<{ scenarioId: string }>>,
  t: (key: string) => string,
  // eslint-disable-next-line consistent-return,sonarjs/cognitive-complexity
): Promise<NotificationContent> => {
  // const notificationActionTextDeleted = t(
  //   'notifications:notificationTypeText.deleted',
  // );
  // const notificationActionTextCreated = t(
  //   'notifications:notificationTypeText.created',
  // );

  switch (notification.type) {
    case NotificationType.ASSET_CREATED:
    case NotificationType.ASSET_UPDATED:
    case NotificationType.ASSET_DELETED: {
      const asset = sjDb.collections.assets?.findOne(
        (asset_) => asset_.id === notification.assetId,
      );

      const assetName = asset?.name || asset?.nickName || asset?.nickname;

      return {
        notificationActionText:
          notification.type === NotificationType.ASSET_DELETED
            ? `${t('notifications:notificationTypeText.assetDeleted.start')} "${
                assetName || 'Asset'
              }" ${t('notifications:notificationTypeText.assetDeleted.finish')}`
            : notification.type === NotificationType.ASSET_UPDATED
            ? `${t('notifications:notificationTypeText.assetUpdated.start')} "${
                assetName || 'Asset'
              }" ${t('notifications:notificationTypeText.assetUpdated.finish')}`
            : `"${assetName || 'Asset'}" ${t('notifications:notificationTypeText.assetCreated')}`,
        title: assetName,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.CONTACT_CREATED:
    case NotificationType.CONTACT_DELETED: {
      const contact = sjDb.collections.contacts?.findOne(
        (contact_) => contact_.id === notification.contactId,
      );

      const contactName = contact?.name || contact?.nickname;

      return {
        notificationActionText:
          notification.type === NotificationType.CONTACT_DELETED
            ? `${t('notifications:notificationTypeText.contactDeleted.start')} "${
                contactName || 'Contact'
              }" ${t('notifications:notificationTypeText.contactDeleted.finish')}`
            : `${t('notifications:notificationTypeText.contactCreated.start')} "${
                contactName || 'Contact'
              }" ${t('notifications:notificationTypeText.contactCreated.finish')}`,
        title: contactName,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.DOCUMENT_DELETED:
    case NotificationType.DOCUMENT_CREATED: {
      let isFound = false;
      let documentName: string | undefined;
      let assetName: string | undefined;
      let beneficiaryName: string | undefined;
      const privateDocument = sjDb.collections.privateDocuments?.findOne(
        (document_) => document_.id === notification.documentId,
      );

      if (privateDocument) {
        isFound = true;
        documentName = privateDocument.name;

        if (privateDocument.assetId) {
          const asset = sjDb.collections.assets?.findOne(
            (_asset) => _asset.id === privateDocument.assetId,
          );
          assetName = asset?.name;
        }

        if (privateDocument.beneficiaryId) {
          const beneficiary = sjDb.collections.beneficiaries?.findOne(
            (_beneficiary) => _beneficiary?.id === privateDocument.beneficiaryId,
          );
          beneficiaryName =
            beneficiary?.nickName ?? beneficiary?.lastName ?? beneficiary?.firstName;
        }
      }

      if (!isFound) {
        const personalIdentifiersDocument = sjDb.collections.personalIdentifiersDocuments?.findOne(
          (document_) => document_.id === notification.documentId,
        );
        if (personalIdentifiersDocument) {
          isFound = true;

          documentName = personalIdentifiersDocument.name;

          if (personalIdentifiersDocument.assetId) {
            const asset = sjDb.collections.assets?.findOne(
              (_asset) => _asset.id === personalIdentifiersDocument.assetId,
            );
            assetName = asset?.name;
          }

          if (personalIdentifiersDocument.beneficiaryId) {
            const beneficiary = sjDb.collections.beneficiaries?.findOne(
              (_beneficiary) => _beneficiary?.id === personalIdentifiersDocument.beneficiaryId,
            );
            beneficiaryName =
              beneficiary?.nickName ?? beneficiary?.lastName ?? beneficiary?.firstName;
          }
        }
      }

      const text = {
        [NotificationType.DOCUMENT_DELETED]: `"${documentName}" ${t(
          'notifications:notificationTypeText.deleted',
        )}`,
        [NotificationType.DOCUMENT_CREATED]: `"${documentName}" ${t(
          'notifications:notificationTypeText.created',
        )}`,
      };

      return {
        notificationActionText: documentName || 'document',
        title: text[notification.type],
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        description: `${assetName ? `${assetName} / ` : ''}${beneficiaryName ?? ''}`,
        date: notification.createdAt || new Date(),
      };
    }

    case NotificationType.SENT_REQUEST_TO_ASSISTANT:
    case NotificationType.ASSISTANT_FILLED_FORM: {
      let documentName: string | undefined;
      let assistantName = '';

      const getDocumentName = (id: string): string | undefined => {
        const pid = sjDb.collections.personalIdentifiersDocuments?.findOne(
          (item) => item.id === id,
        );
        if (pid) return lowerTextAndCapitalizeFirstLetter(pid.personalIdentifierType);
        const pd = sjDb.collections.privateDocuments?.findOne((item) => item.id === id);
        if (pd) return lowerTextAndCapitalizeFirstLetter(pd.privateType);
        const bpdd = sjDb.collections.beneficiaryPersonalDataDocuments?.findOne(
          (item) => item.id === id,
        );
        if (bpdd)
          return sjDb.collections.beneficiaries?.findOne(
            (item) => item.id === bpdd.beneficiaryDtoId,
          )?.nickName;
        return undefined;
      };

      const asset = sjDb.collections.assets?.findOne(
        (_asset) => _asset.id === notification.documentId,
      );

      const assetName = asset?.name ?? asset?.nickName ?? asset?.nickname;

      if (!assetName) {
        documentName = getDocumentName(notification.documentId);
      }

      const assistant = sjDb.collections.assistantMnemonic?.findOne(
        (assis) => assis.assistantProfileId === notification.assistantProfileId.toString(),
      );
      if (assistant) {
        assistantName = assistant.name;
      }

      const text = {
        [NotificationType.SENT_REQUEST_TO_ASSISTANT]: `${t(
          'notifications:notificationTypeText.requestSent',
        )} "${assistantName}"`,
        [NotificationType.ASSISTANT_FILLED_FORM]: `"${assistantName}" ${t(
          `notifications:notificationTypeText.filledForm`,
        )} ${assetName || documentName}`,
      };

      return {
        notificationActionText: text[notification.type],
        description: `${assetName ?? documentName ?? ''}`,
        date: notification.createdAt || new Date(),
        ...(NotificationType.ASSISTANT_FILLED_FORM
          ? {
              delegationRequest:
                notification.type !== NotificationType.SENT_REQUEST_TO_ASSISTANT
                  ? notification?.delegatingRequest
                  : undefined,
            }
          : undefined),
      };
    }
    case NotificationType.FILED_ADDED_TO_INBOX: {
      return {
        notificationActionText: `${notification.amount} file ${t(
          'notifications:notificationTypeText.filedAddedToInbox',
        )}`,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.FILED_DELETED_FROM_INBOX: {
      return {
        notificationActionText: `${notification.amount} file ${t(
          'notifications:notificationTypeText.filedDeletedFromInbox',
        )}`,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.DOCUMENTS_BY_ASSISTANT: {
      return {
        notificationActionText: `${notification.amount} ${t(
          'notifications:notificationTypeText.documentsByAssistant',
        )}`,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.CHECK_PLAN: {
      return {
        notificationActionText: t('notifications:notificationTypeText.checkPlan'),
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.PASSWORD_CHANGE: {
      return {
        notificationActionText: t('notifications:notificationTypeText.passwordChange'),
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.DATA_TRANSFERRED_TO_MY_HUB: {
      return {
        notificationActionText: t('notifications:notificationTypeText.dataTransferredToMyHub'),
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.MY_HUB_CREATED: {
      return {
        notificationActionText: t('notifications:notificationTypeText.myHubCreated'),
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.BENEFICIARY_CREATED:
    case NotificationType.BENEFICIARY_UPDATED:
    case NotificationType.BENEFICIARY_DELETED:
    case NotificationType.BENEFICIARY_ACCEPT_INVITATION:
    case NotificationType.BENEFICIARY_SENT_INVITATION: {
      const texts = {
        [NotificationType.BENEFICIARY_CREATED]: (text: string) =>
          `${t('notifications:notificationTypeText.beneficiaryCreated.start')} "${text}" ${t(
            'notifications:notificationTypeText.beneficiaryCreated.finish',
          )}`,
        [NotificationType.BENEFICIARY_UPDATED]: (text: string) =>
          `${t('notifications:notificationTypeText.beneficiaryUpdated.start')} "${text}" ${t(
            'notifications:notificationTypeText.beneficiaryUpdated.finish',
          )}`,
        [NotificationType.BENEFICIARY_DELETED]: (text: string) =>
          `${t('notifications:notificationTypeText.beneficiaryDeleted.start')} "${text}" ${t(
            'notifications:notificationTypeText.beneficiaryDeleted.finish',
          )}`,
        [NotificationType.BENEFICIARY_ACCEPT_INVITATION]: (text: string) =>
          `"${text}" ${t('notifications:notificationTypeText.beneficiaryAcceptInvitation')}`,
        [NotificationType.BENEFICIARY_SENT_INVITATION]: (text: string) =>
          `${t('notifications:notificationTypeText.beneficiarySentInvitation')} "${text}"`,
      };

      const beneficiary = sjDb.collections.beneficiaries?.findOne(
        (_beneficiary) => _beneficiary.id === notification.beneficiaryId,
      );

      return {
        title: beneficiary?.nickName,
        notificationActionText: texts[notification.type](beneficiary?.nickName || 'Next owner'),
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.SCENARIO_CREATED:
    case NotificationType.TEST_SCENARIO_LAUNCHED:
    case NotificationType.TEST_SCENARIO_ACCEPTED:
    case NotificationType.SCENARIO_ACTIVATED:
    case NotificationType.SCENARIO_RESOLVED:
    case NotificationType.SCENARIO_DEACTIVATED:
    case NotificationType.TRIGGER_SCENARIO_INFO: {
      const texts = {
        [NotificationType.SCENARIO_CREATED]: (text: string) =>
          `${t('notifications:notificationTypeText.scenarioCreated')} "${text}"`,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [NotificationType.TEST_SCENARIO_LAUNCHED]: (_text: string) =>
          `${t('notifications:notificationTypeText.testScenarioLaunched.start')} "${_text}" ${t(
            'notifications:notificationTypeText.testScenarioLaunched.finish',
          )}`,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [NotificationType.TEST_SCENARIO_ACCEPTED]: (_text: string) =>
          `${t('notifications:notificationTypeText.testScenarioAccepted')} `,
        [NotificationType.SCENARIO_ACTIVATED]: (text: string) =>
          `${t('notifications:notificationTypeText.scenarioActivated')} "${text}"`,
        [NotificationType.SCENARIO_RESOLVED]: (text: string) =>
          `${t('notifications:notificationTypeText.scenarioResolved')} "${text}"`,
        [NotificationType.SCENARIO_DEACTIVATED]: (text: string) =>
          `${t('notifications:notificationTypeText.scenarioDeactivated')} "${text}"`,
        [NotificationType.TRIGGER_SCENARIO_INFO]: (text: string, date: string = '') =>
          `${t('notifications:notificationTypeText.triggerScenarioInfo.start')} "${text}" ${t(
            'notifications:notificationTypeText.triggerScenarioInfo.final',
          )} ${dayjs(+date).format('DD MMMM YYYY')}`,
      };

      const scenario = await scenarioLazyQuery({
        variables: {
          scenarioId: notification.scenarioId,
        },
      });

      if (!scenario.data?.scenario) {
        return {
          notificationActionText: texts[notification.type]('Asset'),
          date: notification.createdAt || new Date(),
        };
      }

      const asset = sjDb.collections.assets?.findOne(
        (_asset) => _asset.id === scenario.data?.scenario?.dtoIds?.[0],
      );

      // const scenarioName = sjDb.collections.scenarioMetaInfos?.findOne(
      //   (_scenario) => _scenario.centralDbId === scenario.data?.scenario?.id,
      // );

      if (
        [
          NotificationType.SCENARIO_ACTIVATED,
          NotificationType.SCENARIO_CREATED,
          NotificationType.TEST_SCENARIO_LAUNCHED,
          NotificationType.SCENARIO_DEACTIVATED,
        ].includes(notification.type)
      ) {
        return {
          description: asset?.name || asset?.nickName || asset?.nickname,
          notificationActionText: asset
            ? texts[notification.type](asset?.name || asset?.nickName || asset?.nickname || '')
            : '',
          date: notification.createdAt || new Date(),
        };
      }

      const beneficiary = sjDb.collections.beneficiaries?.findOne(
        (_beneficiary) =>
          _beneficiary.centralDbProfileId === scenario.data?.scenario?.beneficiaryId,
      );

      return {
        title: beneficiary?.nickName || beneficiary?.lastName || beneficiary?.firstName,
        notificationActionText: texts[notification.type](
          asset ? asset.name || asset.nickName || asset.nickname || '' : '',
          String(scenario.data.scenario.triggerValue),
        ),
        description: asset?.name || asset?.nickName || asset?.nickname,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.SCENARIO_EXECUTED: {
      const scenario = await scenarioLazyQuery({
        variables: {
          scenarioId: notification.scenarioId,
        },
      });

      const asset = sjDb.collections.assets?.findOne(
        (_asset) => _asset.id === scenario.data?.scenario?.dtoIds?.[0],
      );

      return {
        title: ' Transfer Algorithm Completed',
        notificationActionText: `Transfer Algorithm has been completed for "${
          asset?.name || asset?.nickName || asset?.nickname || ''
        }"`,
        date: notification.date,
        blockchainTransactionHash: notification.blockchainTransactionHash,
      };
    }
    default: {
      console.error(t('notifications:notificationTypeText.errors.unknownNotification'));
      return {
        notificationActionText: t('notifications:notificationTypeText.errors.unknownNotification'),
        date: new Date(),
      };
    }
  }
};
