import { Flex, Typography } from 'antd';
import styled from 'styled-components';
const { Text, Title } = Typography;

export const NotItems = () => {
  return (
    <Flex vertical justify="center" align="center">
      <Icon />
      <Title level={4}>No items found</Title>
      <StyledText>Please try to adjust your search</StyledText>
    </Flex>
  );
};

const StyledText = styled(Text)`
  &&& {
    font-family: 'Avenir Next Cyr';
  }
`;

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
    <path
      d="M61.9499 19.5719L66.8499 14.1813C67.2865 13.6891 67.5115 13.0448 67.4762 12.3879C67.4408 11.731 67.148 11.1145 66.6611 10.6721C66.1743 10.2296 65.5327 9.99691 64.8754 10.0244C64.2181 10.0518 63.5982 10.3373 63.1499 10.8188L58.2499 16.2063C52.2837 11.6241 44.8131 9.45206 37.3202 10.121C29.8272 10.7899 22.8595 14.2509 17.7993 19.8174C12.739 25.3838 9.95609 32.6491 10.0025 40.1717C10.049 47.6942 12.9214 54.9245 18.0499 60.4281L13.1499 65.8188C12.9245 66.0609 12.7494 66.3454 12.6347 66.6557C12.5201 66.966 12.4681 67.296 12.482 67.6266C12.4958 67.9571 12.575 68.2816 12.7152 68.5813C12.8553 68.881 13.0536 69.1498 13.2984 69.3723C13.5432 69.5948 13.8298 69.7665 14.1415 69.8774C14.4531 69.9884 14.7837 70.0364 15.1141 70.0186C15.4444 70.0008 15.768 69.9177 16.0659 69.7739C16.3639 69.6302 16.6304 69.4287 16.8499 69.1813L21.7499 63.7938C27.7161 68.3759 35.1868 70.548 42.6797 69.879C50.1726 69.2101 57.1404 65.7491 62.2006 60.1826C67.2608 54.6162 70.0438 47.351 69.9973 39.8284C69.9509 32.3058 67.0785 25.0755 61.9499 19.5719ZM14.9999 40C15.0027 35.3717 16.2902 30.8351 18.7191 26.8953C21.148 22.9556 24.6228 19.7674 28.7566 17.6859C32.8904 15.6044 37.5209 14.7114 42.1323 15.1061C46.7438 15.5009 51.1551 17.1681 54.8749 19.9219L21.4374 56.7125C17.2918 52.1337 14.9972 46.1767 14.9999 40ZM39.9999 65C34.6407 65.0057 29.4231 63.2793 25.1249 60.0781L58.5624 23.2875C61.7974 26.8763 63.9225 31.3262 64.68 36.0981C65.4376 40.87 64.7953 45.7592 62.8309 50.1735C60.8665 54.5878 57.6643 58.3379 53.6121 60.9694C49.5599 63.6009 44.8316 65.0009 39.9999 65Z"
      fill="#D0D4DC"
    />
  </svg>
);
