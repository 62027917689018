import { FC, ReactNode } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
const { Text: AntText } = Typography;

type TextSize = '10' | '14';

type SearchMatchTextProps = {
  text: string;
  matchString: string;
  textSize?: TextSize;
};

export const SearchMatchText: FC<SearchMatchTextProps> = ({
  matchString,
  text,
  textSize = '14',
}) => {
  const result: Array<ReactNode> = [];

  if (!matchString) {
    return <Text textSize={textSize}>{text}</Text>;
  }

  let remainingText = text;
  const matchStringLength = matchString.length;

  while (remainingText.length > 0) {
    const matchIndex = remainingText.toLowerCase().indexOf(matchString.toLowerCase());

    if (matchIndex === -1) {
      result.push(
        <Text key={remainingText} textSize={textSize}>
          {remainingText}
        </Text>,
      );
      break;
    }

    if (matchIndex > 0) {
      const beforeMatch = remainingText.substring(0, matchIndex);
      result.push(
        <Text key={beforeMatch} textSize={textSize}>
          {beforeMatch}
        </Text>,
      );
    }

    const matchText = remainingText.substring(matchIndex, matchIndex + matchStringLength);
    result.push(
      <MatchText key={matchText + Math.random()} textSize={textSize}>
        {matchText}
      </MatchText>,
    );

    remainingText = remainingText.substring(matchIndex + matchStringLength);
  }

  return <div>{result}</div>;
};

const Text = styled(AntText)<{ textSize: TextSize }>`
  &&& {
    text-overflow: ellipsis;
    font-family: 'Avenir Next Cyr';
    font-size: ${({ textSize }) => {
      if (textSize === '10') {
        return '10px';
      } else {
        return '14px';
      }
    }};
    font-weight: 400;
    line-height: 20px;
  }
`;

const MatchText = styled(Text)`
  &&& {
    display: inline-block;
    align-items: center;
    background-color: #fcf4e7;
    border-radius: 8px;
    padding: 0;
    color: #000080;
    font-weight: bold;
  }
`;
