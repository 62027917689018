import { ChangeEvent, FC } from 'react';
import { Input, List } from 'antd';
import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';
import { BeneficiaryGroup } from '@/shared/lib/sj-orm/models/beneficiary.dto';

import { CategoryItem } from './categoryItem';
import { NotItems } from './notItems';
import { TabDescription } from './tabDescription';
import { DescriptionInfo, NextOwnerUser, SetInviteUser } from './type';

const groupNameLabel: Record<BeneficiaryGroup, string> = {
  [BeneficiaryGroup.CIRCLE]: 'Circle',
  [BeneficiaryGroup.FAMILY_CLAN]: 'Family Clan',
  [BeneficiaryGroup.FAMILY]: 'Family',
};

const sortOrder: Array<string> = [
  BeneficiaryGroup.FAMILY,
  BeneficiaryGroup.FAMILY_CLAN,
  BeneficiaryGroup.CIRCLE,
];

type CategoryProps = {
  dataSource: Array<NextOwnerUser>;
  descriptionInfo?: DescriptionInfo;
  handleSelect: (value?: string) => void;
  invitedUsers: Array<string>;
  isShowStatus?: boolean;
  searchInputHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  setModalInviteUserState: SetInviteUser;
};

export const Category: FC<CategoryProps> = ({
  dataSource,
  descriptionInfo,
  handleSelect,
  invitedUsers,
  isShowStatus = false,
  searchInputHandler,
  searchValue,
  setModalInviteUserState,
}) => {
  const groupedUsers = dataSource.reduce(
    (groups: Record<BeneficiaryGroup, Array<(typeof dataSource)[number]>>, user) => {
      const group = user.group;

      if (!group) {
        return groups;
      }

      if (!groups[group]) {
        groups[group] = [];
      }

      groups[group].push(user);
      return groups;
    },

    {
      [BeneficiaryGroup.CIRCLE]: [],
      [BeneficiaryGroup.FAMILY_CLAN]: [],
      [BeneficiaryGroup.FAMILY]: [],
    },
  );

  return (
    <Content>
      {descriptionInfo && (
        <TabDescription title={descriptionInfo.title} text={descriptionInfo.text} />
      )}

      <Search placeholder="Search" onChange={searchInputHandler} value={searchValue} />

      <div>
        {dataSource.length === 0 ? (
          <NotItems />
        ) : (
          Object.keys(groupedUsers)
            .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
            .map((group) => {
              const groupName = group as BeneficiaryGroup;

              if (groupedUsers[groupName].length === 0) {
                return null;
              }

              return (
                <Wrapper key={group}>
                  <h3>{groupNameLabel[groupName]}</h3>

                  <List
                    style={{ background: '#ffffff' }}
                    itemLayout="horizontal"
                    dataSource={groupedUsers[groupName]}
                    renderItem={(user) => {
                      const newUserEmail = localStorage.getItem(`new-family-member-added`);

                      return (
                        <CategoryItem
                          handleSelect={handleSelect}
                          isShowStatus={isShowStatus}
                          newUserEmail={newUserEmail}
                          user={user}
                          invitedUsers={invitedUsers}
                          setModalInviteUserState={setModalInviteUserState}
                          searchValue={searchValue}
                        />
                      );
                    }}
                  />
                </Wrapper>
              );
            })
        )}
      </div>
    </Content>
  );
};

const Wrapper = styled.div`
  background: ${COLORS.colorPrimaryBg};
  margin-top: 12px;
`;

const Content = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  position: relative;
  overflow-y: auto;
  height: 60vh;
`;

const Search = styled(Input)`
  &&& {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;
