import { useTranslation } from 'react-i18next';
import { Button, Modal, Typography } from 'antd';

import { Row } from '@/shared/components/layout';

import { TDeleteModal } from './types';

export const DeleteModal = ({
  isModalOpen,
  deleteFunc,
  description,
  setIsModalOpen,
  title,
}: TDeleteModal) => {
  const { t } = useTranslation(['common']);
  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      title={title}
      footer={null}
    >
      <Typography.Text>{description}</Typography.Text>

      <Row nowrap gapColumn={8} marginTop={24}>
        <Button block style={{ height: 40 }} onClick={() => setIsModalOpen(false)}>
          {t('common:cancel')}
        </Button>

        <Button block type="primary" style={{ height: 40 }} onClick={deleteFunc}>
          {t('common:delete')}
        </Button>
      </Row>
    </Modal>
  );
};
